import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import home from './../../assets/img/icons/home.svg';
import heart from './../../assets/img/icons/heart.svg';
import menuGrid from './../../assets/img/icons/menu-grid.svg';
import userCertification from './../../assets/img/icons/user-certification.svg';
import shoppingBag from './../../assets/img/icons/shopping-bag.svg';
import logo from './../../assets/img/icons/logoBrandCollector.svg';
import { openModal } from '../../slices/modals';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getFooterItems } from '../../slices/home';
import './_index.scss';
import { addEmailToNewsLetter } from '../../slices/newsletter';
import { getShopInfo } from '../../slices/shops';

const Footer = () => {
  let currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const { basket } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getFooterItems());
    dispatch(getShopInfo('FASHION_WEB'));
  }, []);

  const footerItems = useSelector((state) => state.home.footerItems);

  const shopInfo = useSelector((state) => state.shops.channelData);
  const socialMediaItems = useSelector((state) => state.home.socialMediaItems);
  const activeSocialMedia =
    socialMediaItems && socialMediaItems?.filter((socialMedia) => socialMedia.enabled);
  const quantityOfProductsInCart = basket?.items?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );

  const [isScrolled, setIsScrolled] = useState(false);
  const api = `${process.env.REACT_APP_API_URL}`;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 300;
      setIsScrolled(scrolled);
    };

    // Add the event listener to track scroll events
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { newsLetterState } = useSelector((state) => state.newsletter);

  const NewsLetterSchema = Yup.object().shape({
    email: Yup.string().email('E-mail non valide').required('E-mail est obligatoire'),
  });

  const newsLetterData = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      if (newsLetterState === 'loading') {
        return;
      }

      dispatch(addEmailToNewsLetter(values)).then((res) => {
        if (res.error.message === 'Email already in use.') {
          toast.error('Email déjà existant');
        } else {
          toast.error('Vous avez déjà une adresse e-mail en utilisation');
        }
      });
    },
    validationSchema: NewsLetterSchema,
  });

  const SocialMediaMappedData = activeSocialMedia.map((data, index) => {
    return (
      <div key={index} className="icon-wrapper" onClick={() => window.open(data.url)}>
        <img src={`${api}/${data?.logoPath}`} alt="" />
      </div>
    );
  });
  return (
    <div className="footer">
      {/* Footer Mobile */}

      {isScrolled && (
        <div className="footer-mobile container">
          <NavLink to={'/'} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            <img src={home} alt="home" />
          </NavLink>
          <NavLink to={'/profile'} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            <img src={userCertification} alt="user certification" />
          </NavLink>
          <NavLink
            to={'/categories'}
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            <img src={menuGrid} alt="menu grid" />
          </NavLink>
          <NavLink
            to={'/profile/favorites'}
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            <img src={heart} alt="heart" />
          </NavLink>
          <div className="basket" onClick={() => dispatch(openModal('basket-modal'))}>
            <div className="basket-count">{quantityOfProductsInCart || 0}</div>
            <img src={shoppingBag} alt="shopping bag" />
          </div>
        </div>
      )}
      {/* Footer Desktop */}
      <>
        <div className="footer-content">
          <div className="footer-content-wrapper container">
            <div className="footer-left">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" className="logo" />
                </Link>
              </div>
              <div className="social-media">
                <p className="title">The brand Collector</p>
                <p className="description"> {shopInfo?.shopBillingData?.street}</p>
                <p className="description">
                  {shopInfo?.shopBillingData?.postcode} {shopInfo?.shopBillingData?.city}
                </p>
                <p className="description">{shopInfo?.contactEmail}</p>
                <p className="description">{shopInfo?.contactPhoneNumber}</p>
              </div>
            </div>
            <div className="footer-information">
              {footerItems?.slice(0, 2)?.map((item, index) => {
                return (
                  <div key={index} className="footer-information-link">
                    <p className="title">{item.description}</p>
                    <div className="all-links">
                      {item.footerListDetails.map((detail, index) => {
                        return (
                          <NavLink key={index} className="link" to={`/${detail.url}`}>
                            {detail.name}
                          </NavLink>
                        );
                      })}
                    </div>
                  </div>
                );
              })}

              <div className="footer-information-link">
                <p className="title">Newsletter</p>
                <p className="description">
                  FOR MORE FASHION AND LUXURY TIPS, SUBSCRIBE TO OUR NEWSLETTER
                </p>

                <form className="login-container" onSubmit={newsLetterData.handleSubmit}>
                  <input
                    className="mail-input"
                    type="text"
                    value={newsLetterData.values.email}
                    name="email"
                    onChange={newsLetterData.handleChange}
                    placeholder="Your e-mail"
                  />
                  <button className="login-btn" type="submit">
                    {newsLetterState === 'loading' && <span className="loading-spinner"></span>}
                    SIGN-UP
                  </button>
                  <div
                    className={
                      newsLetterData.errors.email
                        ? 'error-wrapper newsletter-error-msg'
                        : 'error-wrapper'
                    }
                  >
                    {newsLetterData.errors.email && <span>{`${newsLetterData.errors.email}`}</span>}
                  </div>
                </form>

                <div className="media-container">
                  <p className="paragraphe">Follow us on :</p>

                  {SocialMediaMappedData}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>
            © {currentYear + ' '}
            <Link to="http://www.dotit-corp.com" target={'_blank'}>
              DOTIT
            </Link>
            . Tous droits réservés.
          </p>
        </div>
      </>
    </div>
  );
};

export default Footer;
