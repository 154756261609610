import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import axiosForCategory from '../utilities/axiosForCategory';

const initialState = {
  productCode: '',
  product: {},
  productStatus: 'idle',
  productSuccess: '',
  productError: '',
  products: [],
  productsMobile: [],
  allProductsPagination: {},
  allProductsFilter: [],
  allProductsTaxons: [],
  categoryTaxonName: '',
  allProductsStatus: 'idle',
  allProductsAssociation: [],
  allProductsAssociationStatus: 'idle',
  moreProductsAssociation: [],
  moreProductsAssociationStatus: 'idle',
  allSearchedProducts: [],
  searchProductsStatus: 'idle',
  allSearchedAdvandcedProducts: [],
  searchProductAdvandcedsStatus: 'idle',
  allProductsByBrand: {},
  allProductsByBrandStatus: 'idle',
  currentProductId: '',
  mainProduct: false,
  filterQuery: [],
  suggestionProduct: [],
  suggestionProductState: 'idle',
  bannerCategory: [],
};

export const getProduct = createAsyncThunk('products/getProduct', async (code, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/products/${code}`);
    data = await response.data;

    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getProductAssociation = createAsyncThunk(
  'products/getProductAssociation',
  async (associationID, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/product-associations/${associationID}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllProducts = createAsyncThunk(
  'products/getAllProducts',
  async (query, thunkAPI) => {
    let data;
    const token = localStorage.getItem('token');
    const headers = {
      'Accept-Language': 'en_US',
    };

    // Conditionally add the Authorization header
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    try {
      const response = await axiosForCategory.get(
        `/api/v2/shop/products?${new URLSearchParams(query).toString()} `,
        { headers }
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllProductsByBrand = createAsyncThunk(
  'products/getAllProductsByBrand',
  async (brandCode, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/brand/${brandCode}`);

      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const searchProducts = createAsyncThunk(
  'products/searchProducts',
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/en_US/auto-complete/product`, {
        params: query,
      });

      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const searchAdvandcedProducts = createAsyncThunk(
  'products/searchAdvancedProducts',
  async ({ query, type }, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/en_US/auto-complete/product?query=${query}&type=${type}`);

      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSuggetProduct = createAsyncThunk(
  'products/getSuggetProduct',
  async ({ taxonCode, minPrice, limit }, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/suggested-products/`, {
        params: {
          'taxonCode[]': taxonCode,
          minPrice,
          limit,
        },
      });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProducts: (state) => {
      state.product = {};
      state.products = [];
      state.allProductsByBrand = {};
      state.allSearchedProducts = [];
      state.allSearchedAdvandcedProducts = [];
    },
    resetProductsInMobile: (state) => {
      state.productsMobile = [];
    },
    resetProductsAssociation: (state) => {
      state.moreProductsAssociation = [];
    },

    editProductWishListState: (state, action) => {
      if (state.product.code === action.payload) state.product.wishlist = !state.product.wishlist;
    },

    getCurrentProductIdFromProduct: (state, action) => {
      state.currentProductId = action.payload;
    },

    editProductsHighlightAssociations: (state) => {
      state.allProductsAssociation.associatedProducts.map((product) => {
        if (product.id === state.currentProductId) {
          product.wishlist = !product.wishlist;
        }
      });
    },

    setMainProduct: (state, action) => {
      state.mainProduct = action.payload;
    },

    editProductsByBrand: (state) => {
      state.allProductsByBrand.activeProducts?.map((product) => {
        if (product.id === state.currentProductId) {
          product.wishlist = !product.wishlist;
        }
      });
    },

    setFilterQuery: (state, action) => {
      state.filterQuery = action.payload;
    },
    removeFilterQuery: (state) => {
      state.filterQuery = {};
    },
    editProductCategories: (state) => {
      state.productsMobile.map((product) => {
        if (product.id === state.currentProductId) {
          product.wishlist = !product.wishlist;
        }
      });

      state.products.map((product) => {
        if (product.id === state.currentProductId) {
          product.wishlist = !product.wishlist;
        }
      });
    },
  },
  extraReducers: {
    [getProduct.pending]: (state) => {
      state.productStatus = 'loading';
    },
    [getProduct.fulfilled]: (state, action) => {
      state.productStatus = 'succeeded';
      state.product = action.payload;
    },
    [getProduct.rejected]: (state) => {
      state.productStatus = 'failed';
    },
    [getProductAssociation.pending]: (state) => {
      state.allProductsAssociationStatus = 'loading';
    },
    [getProductAssociation.fulfilled]: (state, action) => {
      state.allProductsAssociationStatus = 'succeeded';
      state.allProductsAssociation = action.payload;
      state.moreProductsAssociation = [...state.moreProductsAssociation, action.payload];
    },
    [getProductAssociation.rejected]: (state) => {
      state.allProductsAssociationStatus = 'failed';
    },
    [getAllProducts.pending]: (state) => {
      state.allProductsStatus = 'loading';
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.allProductsStatus = 'succeeded';
      state.products = action.payload.data?.['hydra:member'];
      state.productsMobile = action.payload.data?.['hydra:member'];

      state.allProductsPagination = action.payload.pagination;
      state.allProductsFilter = action.payload.filters;
      state.allProductsTaxons = action.payload.taxons || [];
      state.categoryTaxonName = action.payload.filterTaxonName
        ? action.payload.filterTaxonName
        : action.payload.filterBrandName;
      state.bannerCategory = action.payload.taxons?.taxonSelected;
    },
    [getAllProducts.rejected]: (state) => {
      state.allProductsStatus = 'failed';
    },
    [getAllProductsByBrand.pending]: (state) => {
      state.allProductsByBrandStatus = 'loading';
    },
    [getAllProductsByBrand.fulfilled]: (state, action) => {
      state.allProductsByBrandStatus = 'succeeded';
      state.allProductsByBrand = action.payload;
    },
    [getAllProductsByBrand.rejected]: (state) => {
      state.allProductsByBrandStatus = 'failed';
    },
    [searchProducts.pending]: (state) => {
      state.searchProductsStatus = 'loading';
    },
    [searchProducts.fulfilled]: (state, action) => {
      state.searchProductsStatus = 'succeeded';
      state.allSearchedProducts = action.payload?.items;
    },
    [searchProducts.rejected]: (state) => {
      state.searchProductsStatus = 'failed';
    },
    [searchAdvandcedProducts.pending]: (state) => {
      state.searchProductAdvandcedsStatus = 'loading';
    },
    [searchAdvandcedProducts.fulfilled]: (state, action) => {
      state.searchProductAdvandcedsStatus = 'succeeded';
      state.allSearchedAdvandcedProducts = action.payload;
    },
    [searchAdvandcedProducts.rejected]: (state) => {
      state.searchProductAdvandcedsStatus = 'failed';
    },

    [getSuggetProduct.pending]: (state) => {
      state.suggestionProductState = 'loading';
    },
    [getSuggetProduct.fulfilled]: (state, action) => {
      state.suggestionProductState = 'succeeded';

      state.suggestionProduct = action.payload;
    },
    [getSuggetProduct.rejected]: (state) => {
      state.suggestionProductState = 'failed';
    },
  },
});

export const {
  resetProducts,
  editProductWishListState,
  getCurrentProductIdFromProduct,
  editProductsHighlightAssociations,
  setMainProduct,
  editProductsByBrand,
  editProductCategories,
  setFilterQuery,
  removeFilterQuery,
  resetProductsInMobile,
  resetProductsAssociation,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
