import React from 'react';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import dropRight from './../../assets/img/icons/iconRight.png';
import './_index.scss';

function DirectLink({ icon, title, className, link, onClose }) {
  return (
    <Link
      className="direct-link"
      to={link}
      onClick={() => {
        onClose();
      }}
    >
      <div className="title">
        {icon && <img src={icon} alt="icon"  />}
        <p className={className && className}>{title}</p>
      </div>
      <IconButton>
        <img src={dropRight} alt="drop right"  className='right'/>
      </IconButton>
    </Link>
  );
}

export default DirectLink;
