import React, { useEffect, useState } from 'react';

import { Box, IconButton, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import googleMeetIcon from './../../../assets/img/icons/googlemeet.svg';
import outlookIcon from './../../../assets/img/icons/outlook.svg';
import yahooIcon from './../../../assets/img/icons/yahoo.svg';
import appleIcon from './../../../assets/img/icons/apple.svg';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';

function AddToCalenderModal({ id, open, handleClose, data }) {
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
    borderRadius: '5px',
  };

  const formatDateForCalendar = (dateStr) => {
    const date = new Date(dateStr);
    return date.toISOString().replace(/[-:]|\.\d{3}/g, '');
  };

  const generateGoogleCalendarUrl = () => {
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      data?.data?.translations?.en_US?.name
    )}&dates=${formatDateForCalendar(data?.data?.startAt)}/${formatDateForCalendar(
      data?.data?.endAt
    )}&details=${encodeURIComponent(
      data?.data?.translations?.en_US?.descriptionWhenLinked
    )}&location=${encodeURIComponent(data?.data?.location)}`;
  };

  const generateOutlookCalendarUrl = () => {
    return `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodeURIComponent(
      data?.data?.translations?.en_US?.name
    )}&body=${encodeURIComponent(
      data?.data?.translations?.en_US?.descriptionWhenLinked
    )}&startdt=${formatDateForCalendar(data?.data?.startAt)}&enddt=${formatDateForCalendar(
      data?.data?.endAt
    )}&location=${encodeURIComponent(data?.data?.location)}`;
  };

  const generateAppleCalendarUrl = () => {
    return `data:text/calendar;charset=utf-8,BEGIN:VCALENDAR%0D%0AVERSION:2.0%0D%0ABEGIN:VEVENT%0D%0ASUMMARY:${encodeURIComponent(
      data?.data?.translations?.en_US?.name
    )}%0D%0ADTSTART:${formatDateForCalendar(
      data?.data?.startAt
    )}%0D%0ADTEND:${formatDateForCalendar(data?.data?.endAt)}%0D%0ADTSTAMP:${
      data?.data?.startAt
    }%0D%0ALOCATION:${encodeURIComponent(
      data?.data?.location
    )}%0D%0ADESCRIPTION:${encodeURIComponent(
      data?.data?.translations?.en_US?.descriptionWhenLinked
    )}%0D%0AEND:VEVENT%0D%0AEND:VCALENDAR`;
  };

  const generateYahooCalendarUrl = () => {
    return `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(
      data?.data?.translations?.en_US?.name
    )}&st=${formatDateForCalendar(data?.data?.startAt)}&et=${formatDateForCalendar(
      data?.data?.endAt
    )}&desc=${encodeURIComponent(
      data?.data?.translations?.en_US?.descriptionWhenLinked
    )}&in_loc=${encodeURIComponent(data?.data?.location)}`;
  };

  const handleGoogleCalendar = () => {
    window.open(generateGoogleCalendarUrl(), '_blank');
  };

  const handleOutlookCalendar = () => {
    window.open(generateOutlookCalendarUrl(), '_blank');
  };

  const handleAppleCalendar = () => {
    window.open(generateAppleCalendarUrl(), '_blank');
  };

  const handleYahooCalendar = () => {
    window.open(generateYahooCalendarUrl(), '_blank');
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
      className="review-modal"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="modal-review-wrapper">
          <p className="modal-add-title"></p>
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>

          <div className="modal-content">
            <div className="added-to-card-container">
              <button className="basket-btn" onClick={() => handleGoogleCalendar()}>
                Add to calender <img src={googleMeetIcon} />
              </button>
              <button className="basket-btn" onClick={() => handleOutlookCalendar()}>
                Add to calender <img src={outlookIcon} />
              </button>
            </div>

            <div className="added-to-card-container">
              <button className="basket-btn" onClick={() => handleYahooCalendar()}>
                Add to calender <img src={yahooIcon} />
              </button>
              <button className="basket-btn" onClick={() => handleAppleCalendar()}>
                Add to calender <img src={appleIcon} />
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default AddToCalenderModal;
