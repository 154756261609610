import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Drawer, IconButton } from '@mui/material';
import Button from '../../Button';
import ProductBasketCard from '../../ProductBasketCard';
import close from './../../../assets/img/icons/close.svg';
import WindowDimensions from '../../../utilities/windowDimenssions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './_index.scss';

function BasketModal({ id, open, handleClose, data }) {
  const { width, height } = WindowDimensions();
  const isMobile = width < 768;

  const { basket, getBasketStatus } = useSelector((state) => state.order);
  const navigate = useNavigate();

  useEffect(() => {}, [basket]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        handleClose(id);
      }}
      className="modal"
    >
      <div className="basket-modal-wrapper">
        <div className="basket-modal-top">
          <div className="modal-header">
            <p className="modal-title">Shopping  Bag ({basket?.items?.length})</p>
            <IconButton className="close" onClick={() => handleClose(id)}>
              <img src={close} alt="close" />
            </IconButton>
          </div>
          <Divider />

          {/* <div className="free-shipping">
            <div className="shipping-icon">
              <img src={track} alt="track" />
            </div>
          //   <div className="free-shipping-content">
          //     <p className="title">LIVRAISON GRATUITE !</p>
          //     <p className="description">Livraison gratuite à partir de 20.000.00 DA d’achat</p>
          //   </div>
          // </div> */}
          <Divider />

          <div className="modal-content">
            {getBasketStatus === 'loading' ? (
              <>
                <ProductBasketCard isLoading={true} />
                <ProductBasketCard isLoading={true} />
              </>
            ) : basket?.items?.length > 0 ? (
              basket?.items?.map((product, index) => (
                <ProductBasketCard
                  key={index}
                  data={product}
                  isLoading={false}
                  closeModal={() => handleClose(id)}
                />
              ))
            ) : (
              <p>Your cart is empty.</p>
            )}
          </div>
        </div>
        <div className="basket-modal-bottom">
          <div className="basket-bottom__caption">
          <div className="caption">
            *Please note, price do not include duties or taxes. if your parcel incurs duties, these
            will need to be paid upon delivery
            </div>
          </div>
          <div className="basket-bottom__wrapper">
            <div className="sub-total">
              <p className="title">SHIPPING :</p>
              <p className="text-description">Calcultated At checkout</p>
            </div>
          </div>
          {!isMobile && (
            <div className="basket-bottom__divider ">
              <Divider />{' '}
            </div>
          )}
          <div className="basket-bottom__price">
            <div className="sub-total">
              <p className="title">Total :</p>
              <p className="price-total">{basket?.formattedTotal || 0} </p>
            </div>
            <div className="buttons-wrapper">
              <Button
                text="PROCCED TO CHECKOUT"
                onClick={() => {
                  basket?.items?.length === 0
                    ? toast.warn('Your cart is empty.')
                    : navigate('/tunnel-achat');
                  handleClose(id);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default BasketModal;
