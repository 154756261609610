import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { NavLink } from 'react-router-dom';

import './_index.scss';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function BpCheckbox({ label, value, ...others }) {
  return (
    <FormControlLabel
      control={<Checkbox style={{ display: 'none' }} />}
      label={<span className="label-withoutcheckbox">{capitalizeFirstLetter(label)}</span>}
    />
  );
}

export default function CustomizedWithoutCheckbox({ label, value }) {
  return (
    <div className="label-container">
      <BpCheckbox label={label} value={value} />
    </div>
  );
}
