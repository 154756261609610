import { Collapse, IconButton } from '@mui/material';
import React, { useState } from 'react';
import plusIcon from './../../assets/img/icons/iconPlus.svg';
import hyphen from './../../assets/img/icons/iconMinus.png';
import './_index.scss';

const CollapseData = ({ children, collapseTitle, className, defaultValue, isInFilter }) => {
  const [open, setOpen] = useState(defaultValue || false);
  const iconButtonStyle = {
    '&:hover': {
      backgroundColor: 'rgba(255, 245, 240, 0.698)',
    },
  };
  return (
    <React.Fragment>
      <div className={`collapsed ${className && className}`} onClick={() => setOpen(!open)}>
        {collapseTitle && (
          <p className={open ? 'title-collapse active' : 'title-collapse'}>{collapseTitle}</p>
        )}
        <div className="btn-collapse">
          <IconButton aria-label="expand row" lg={iconButtonStyle}>
            {open ? (
              <img src={hyphen} alt={'plus icon'} className="hyphen" />
            ) : (
              <img src={plusIcon} alt={'hyphen'} className="plus" />
            )}
          </IconButton>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit className="custom-collapse">
        <div className="element">{children}</div>
      </Collapse>
    </React.Fragment>
  );
};
export default CollapseData;
