import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { toast } from 'react-toastify';

const initialState = {
  homeState: '',
  getBannerState: '',
  allBannersState: '',
  footerItems: [],
  socialMediaItems: [],
  menuItems: [],
  getMenuItemsState: 'idle',
  allBanners: [],
  BannerCode: [],
};

export const getFooterItems = createAsyncThunk('home/getFooterItems', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/footer-lists`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getSocialMediaItems = createAsyncThunk(
  'home/getSocialMediaItems',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/social-medias`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMenuItems = createAsyncThunk('home/getMenuItems', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/menus/MAINTBC`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    console.error('Error:', error);

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getBanners = createAsyncThunk('home/getBanners', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/banners`, {
      params: {
        itemsPerPage: 48,
      },
    });

    data = await response.data;

    if (response.status === 200) {
      return { data };
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(toast.error(message));

    return thunkAPI.rejectWithValue(message);
  }
});

export const getBannerByCode = createAsyncThunk(
  'home/getBannerByCode',
  async (brandCode, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/banner/${brandCode}`);
      data = await response.data;
      if (response.status === 200) {
        return { data };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contactUs = createAsyncThunk('home/contactUs', async (values, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/api/v2/shop/contact-requests`, {
      message: values.message,
      email: values.email,
      object: values.object,
      phoneNumber: values.phoneNumber,
    });

    data = await response.data;
    if (response.status === 201 || 200) {
      thunkAPI.dispatch(toast.success('Message sent successfully'));
      return { data };
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message = error?.detail.toString();
    thunkAPI.dispatch(toast.error(message));
  }
});

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    [getFooterItems.pending]: (state) => {
      state.homeState = 'loading';
    },
    [getFooterItems.fulfilled]: (state, action) => {
      state.homeState = 'succeeded';
      state.footerItems = action.payload;
    },
    [getFooterItems.rejected]: (state) => {
      state.homeState = 'failed';
    },
    [getSocialMediaItems.pending]: (state) => {
      state.homeState = 'loading';
    },
    [getSocialMediaItems.fulfilled]: (state, action) => {
      state.homeState = 'succeeded';
      state.socialMediaItems = action.payload;
    },
    [getSocialMediaItems.rejected]: (state) => {
      state.homeState = 'failed';
    },
    [getMenuItems.pending]: (state) => {
      state.getMenuItemsState = 'loading';
    },
    [getMenuItems.fulfilled]: (state, action) => {
      state.getMenuItemsState = 'succeeded';
      state.menuItems = action.payload;
    },
    [getMenuItems.rejected]: (state) => {
      state.getMenuItemsState = 'failed';
    },
    [getBanners.pending]: (state) => {
      state.allBannersState = 'loading';
    },
    [getBanners.fulfilled]: (state, action) => {
      state.allBannersState = 'succeeded';
      state.allBanners = action.payload.data;
    },
    [getBanners.rejected]: (state) => {
      state.allBannersState = 'failed';
    },
    [getBannerByCode.pending]: (state) => {
      state.getBannerState = 'loading';
    },
    [getBannerByCode.fulfilled]: (state, action) => {
      state.getBannerState = 'succeeded';
      state.BannerCode = action.payload.data;
    },
    [getBannerByCode.rejected]: (state) => {
      state.getBannerState = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const {} = slice.actions;

export default slice;
