import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Modal } from '@mui/material';
import close from './../../../assets/img/icons/close.svg';
import ThumbnailPlugin from './../../../utilities/ThumbnailSlider.js';
import { useKeenSlider } from 'keen-slider/react';
import emptyImage from './../../../assets/img/empty-image.jpeg';
import ArrowLeft from './../../../assets/img/icons/arrow-slider-left.svg';
import ArrowRight from './../../../assets/img/icons/arrow-slider-right.svg';

import './_index.scss';

function PreviewProductSheetModal({ id, open, handleClose, data }) {
  const { product, productStatus } = useSelector((state) => state.products);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 5,
        spacing: 20,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="preview-product-sheet-modal-title"
      aria-describedby="preview-product-sheet-modal-description"
      className="preview-product-sheet-modal"
      disableAutoFocus={true}
      BackdropProps={{ style: { background: 'transparent' } }}
    >
      <div className="preview-product-sheet-modal-wrapper">
        <IconButton className="close" onClick={() => handleClose(id)}>
          <img src={close} alt="close" />
        </IconButton>
        <div ref={sliderRef} className="keen-slider">
          {product?.images?.length > 0 ? (
            product?.images?.map((item, index) => (
              <div key={index} className={`keen-slider__slide number-slide${index + 1}`}>
                <div className="current-product-wrapper">
                  <img
                    className="img-product"
                    src={process.env.REACT_APP_API_URL + item.path}
                    alt="product"
                  />
                </div>
              </div>
            ))
          ) : (
            <div className={`keen-slider__slide number-slide${1}`}>
              <div className="current-product-wrapper">
                <img className="img-product" src={emptyImage} alt="product" />
              </div>
            </div>
          )}
        </div>
        <div className="thumbnail-wrapper">
          <div ref={thumbnailRef} className="keen-slider thumbnail">
            {product?.images?.length > 0 ? (
              product?.images?.map((item, index) => (
                <div key={index} className={`keen-slider__slide number-slide${index + 1}`}>
                  <div className="product-thumbnail">
                    <img src={process.env.REACT_APP_API_URL + item.path} alt="product" />
                  </div>
                </div>
              ))
            ) : (
              <div className={`keen-slider__slide number-slide${1}`}>
                <div className="product-thumbnail">
                  <img className="img-product" src={emptyImage} alt="product" />
                </div>
              </div>
            )}
          </div>
          {loaded && instanceRef.current && (
            <div className="arrow-wrapper">
              <Arrow
                left
                onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
                disabled={currentSlide === 0}
              />
              <Arrow
                onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <IconButton
      onClick={props.onClick}
      className={`${props.left ? 'preview-image' : 'next-image'} ${disabeld}`}
    >
      <img src={props.left ? ArrowLeft : ArrowRight} alt="arrow" />
    </IconButton>
  );
}

export default PreviewProductSheetModal;
