import { Collapse, IconButton } from '@mui/material';
import React, { useState } from 'react';
import plusIcon from './../../assets/img/icons/plus-icon.svg';
import hyphen from './../../assets/img/icons/minusIcon.png';
import './_index.scss';

const CollapseDataFilter = ({ children, collapseTitle, className, defaultValue, isInFilter }) => {
  const [open, setOpen] = useState(defaultValue || false);
  const iconButtonStyle = {
    '&:hover': {
       backgroundColor: 'transparent',
      
    },
  };
  return (
    <React.Fragment>
      <div className={`collapse ${className && className}`} onClick={() => setOpen(!open)}>
        {collapseTitle && (
          <p className={open ? 'title-collapse active' : 'title-collapse'}>{'-' + collapseTitle}</p>
        )}
        <div className="btn-collapse">
          <IconButton aria-label="expand row" sx={iconButtonStyle}>
            {open ? (
              <img src={hyphen} alt={'plus icon'} className="plus" />
            ) : (
              <img src={plusIcon} alt={'hyphen'} className="plus" />
            )}
          </IconButton>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
};
export default CollapseDataFilter;
