import React from 'react';
import { HeaderProduct, Footer } from './../index';
import './_index.scss';

const MainLayout = ({ children }) => {
  return (
    <div className="main-layout">
      <HeaderProduct />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {};

export default MainLayout;
