import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { toast } from 'react-toastify';

const initialState = {
  getEventsState: '',
  allEvents: [],
  eventState: '',
  event: [],
};

export const getEvents = createAsyncThunk('home/getEvents', async (sectionCode, thunkAPI) => {
  let data;
  try {
    const response = await axios.get('/api/v2/shop/cms-plugin/events', { sectionCode });

    data = await response.data;

    if (response.status === 200) {
      return { data };
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(toast.error(message));

    return thunkAPI.rejectWithValue(message);
  }
});

export const getEventsBySlug = createAsyncThunk(
  'home/getEventsBySlug',
  async ({ slug, id }, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/cms-plugin/event-by-slug/${slug}`, {
        params: { id },
        headers: {
          'Accept-Language': 'en_US',
        },
      });

      data = await response.data;

      if (response.status === 200) {
        return { data };
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.getEventsState = 'loading';
    },
    [getEvents.fulfilled]: (state, action) => {
      state.getEventsState = 'succeeded';
      state.allEvents = action?.payload?.data;
    },
    [getEvents.rejected]: (state) => {
      state.getEventsState = 'failed';
    },

    [getEventsBySlug.pending]: (state) => {
      state.eventState = 'loading';
    },
    [getEventsBySlug.fulfilled]: (state, action) => {
      state.eventState = 'succeeded';
      state.event = action?.payload?.data;
    },
    [getEventsBySlug.rejected]: (state) => {
      state.eventState = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const {} = slice.actions;

export default slice;
