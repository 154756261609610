import React, { useState } from 'react';
import CustomizedCheckbox from '../Checkbox';
import './_index.scss';

function GroupBoxFilter({
  checkboxArray,
  hasShowMore,
  filterList,
  setFilterList,
  params,
  setParams,
  name,
}) {
  const [showenBrand, setShowenBrand] = useState(5);
  const [checkArray, setCheckArray] = React.useState([]);
  const handleInputChange = (event) => {
    const { value } = event.target;
    const newFilterList = filterList.includes(value)
      ? filterList.filter((item) => item !== value)
      : [...filterList, value];
    const selectedValues = checkboxArray
      .map((item) => item.value)
      .filter((value) => newFilterList.includes(value)); // Change to filter by slug

    setFilterList(selectedValues);
    setParams({
      ...params,
      [name]: selectedValues,
    });
  };
  return (
    <div className="group-checkbox-filter">
      {checkboxArray?.slice(0, showenBrand).map((checkbox, index) => {
        return (
          <CustomizedCheckbox
            key={index}
            label={checkbox.label}
            value={checkbox.value}
            checkArray={checkArray}
            setCheckArray={setCheckArray}
            isChecked={checkbox?.isSelectedForFilter === 'true'}
            handleInputChange={handleInputChange}
          />
        );
      })}
      {hasShowMore && checkboxArray.length > showenBrand && (
        <p
          className="show-more"
          onClick={() =>
            setShowenBrand(showenBrand < checkboxArray.length ? checkboxArray.length : 5)
          }
        >
          {showenBrand < checkboxArray?.length ? 'More' : 'Less'}
        </p>
      )}
    </div>
  );
}

export default GroupBoxFilter;
