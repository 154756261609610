import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './_index.scss';

function MenuPopoverDesktop({ title, secondLevelItems, image, linkFirstLevel }) {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState('');
  function navigationToFirstLevel() {
    navigate(linkFirstLevel);
    setIsHover(false);
  }
  const onMouseEnterHandler = (data) => {
    setIsHover(true);
    setData(data);
  };
  const onMouseLeaveHandler = () => {
    setIsHover(false);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [sortSecondLevelItems, setSortSecondLevelItems] = useState();

  useEffect(() => {
    let thirdItems = [];
    thirdItems = secondLevelItems?.slice().sort((i1, i2) => (i1.position < i2.position ? -1 : 1));
    setSortSecondLevelItems(thirdItems);
  }, [secondLevelItems]);

  return (
    <div
      className="menu-popover-desktop"
      onMouseLeave={() => onMouseLeaveHandler('')}
      onMouseEnter={() => onMouseEnterHandler(title)}
    >
      <NavLink
        onClick={() => {
          onMouseLeaveHandler();
        }}
        className="title-first-level"
        to={title !== 'Marques' && `${linkFirstLevel ? linkFirstLevel : ''}`}
      >
        {capitalizeFirstLetter(title)}
      </NavLink>
      <div
        className={`menu-modal ${isHover ? 'hover-menu' : ''} ${
          title !== 'Brands' ? 'menu-modal' : 'brand-modal'
        }`}
      >
        {title !== 'Brands' ? (
          <div className="menu-modal-content with-image">
            <p className="title">
              {capitalizeFirstLetter(title)}
              <ul className="list">
                {sortSecondLevelItems?.slice(0, 8)?.map((secondLevelItem, index) => {
                  return (
                    <li
                      className="list-item"
                      key={index}
                      onClick={() => {
                        onMouseLeaveHandler();
                      }}
                    >
                      <Link
                        to={
                          secondLevelItem?.translation?.url.includes('categories')
                            ? `/categories${
                                secondLevelItem?.translation?.url?.split('categories')[1]
                              }`
                            : secondLevelItem?.translation?.url
                        }
                      >
                        {capitalizeFirstLetter(secondLevelItem.label)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </p>

            <img
              src={process.env.REACT_APP_API_URL + image}
              alt="menu-img"
              onClick={navigationToFirstLevel}
            />
          </div>
        ) : (
          <div className="menu-modal-content with-image">
            <p className="title">
              {capitalizeFirstLetter(title)}
              <ul className="list">
                {sortSecondLevelItems?.slice(0, 18)?.map((secondLevelItem, index) => {
                  return (
                    <li
                      className="list-item"
                      key={index}
                      onClick={() => {
                        onMouseLeaveHandler();
                      }}
                    >
                      <Link
                        to={
                          secondLevelItem?.translation?.url.includes('categories')
                            ? `/categories${
                                secondLevelItem?.translation?.url?.split('categories')[1]
                              }`
                            : secondLevelItem?.translation?.url
                        }
                      >
                        {capitalizeFirstLetter(secondLevelItem.label)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </p>

            <img src={process.env.REACT_APP_API_URL + image} alt="menu-img" />
          </div>
        )}
      </div>
    </div>
    // </div>
  );
}

export default MenuPopoverDesktop;
