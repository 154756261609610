import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterQuery } from '../../slices/products';
import PriceInputRange from '../InputRange';
import CollapseDataFilter from '../CollapseFilter';
import GroupRadioFilterByColor from '../GroupRadioFilterByColor';
import GroupCheckboxFilter from '../GroupCheckboxFilter';
import './_index.scss';
import GroupBoxFilter from '../CheckBoxFilter';

function Filter({
  className,
  hasCategory,
  filterData,
  isLoading,
  currentCodecategory,
  categoryName,
  params,
  setParams,
}) {
  const dispatch = useDispatch();

  const formatedBrands = filterData?.brands?.map((brand) => {
    return {
      label: brand?.name,
      value: brand?.code,
      isSelectedForFilter: brand?.isSelectedForFilter,
    };
  });

  const [filterList, setFilterList] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    dispatch(setFilterQuery(params));
  }, [dispatch, params]);
  return (
    <div className={isLoading ? className + ' filter loading' : className + ' filter'}>
      <p className="category-title">{capitalizeFirstLetter(currentCodecategory)}</p>
      <p className="filter-title">FILTRE</p>
      <div className="filter-content">
        {currentCodecategory !== 'newarrival' && !hasCategory?.taxonSelected?.slug === 'auctions' && (
          <div className="filter-content-item has-padding-left">
            <CollapseDataFilter
              collapseTitle={categoryName.includes('brands') ? 'brands' : 'Category'}
              defaultValue={true}
              className={'in-filter'}
            >
              <GroupCheckboxFilter
                checkboxArray={hasCategory?.taxonChildren || hasCategory?.taxonSiblings}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name={'categories'}
                hasShowMore={true}
              />
            </CollapseDataFilter>
          </div>
        )}

        {filterData?.brands && filterData?.brands?.length > 0 && !categoryName.includes('brands') && (
          <div className="filter-content-item has-padding-left">
            <CollapseDataFilter
              collapseTitle={'Brand'}
              defaultValue={false}
              className={'in-filter'}
            >
              <GroupBoxFilter
                checkboxArray={formatedBrands}
                hasShowMore={true}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name={'brand'}
              />
            </CollapseDataFilter>
          </div>
        )}
        {filterData?.prices && filterData?.prices?.maxPrice > 0 && (
          <div className="filter-content-item has-padding-left">
            <CollapseDataFilter
              collapseTitle={'Price'}
              defaultValue={false}
              className={'in-filter'}
            >
              <PriceInputRange
                min={filterData?.prices?.minPrice}
                max={filterData?.prices?.maxPrice}
                params={params}
                setParams={setParams}
                name="price"
              />
            </CollapseDataFilter>
          </div>
        )}

        {filterData?.attributes &&
          filterData?.attributes
            ?.filter(
              (data) =>
                (data?.nbWithoutFilter > 0 || (data?.nb !== undefined && data?.nb === 0)) &&
                !(data?.code === 'shipping-deliverytime' || data?.code === 'shipping-cost')
            )
            ?.map((attribute, index) => (
              <div className="filter-content-item has-padding-left" key={index}>
                <CollapseDataFilter
                  collapseTitle={attribute?.name}
                  defaultValue={false}
                  className={'in-filter'}
                >
                  <GroupRadioFilterByColor
                    data={attribute?.values}
                    params={params}
                    setParams={setParams}
                    filterList={filterList}
                    hasShowMore={true}
                    setFilterList={setFilterList}
                    name={attribute?.name}
                    attributeCode={attribute?.code}
                  />
                </CollapseDataFilter>
              </div>
            ))}
      </div>
    </div>
  );
}

export default Filter;
