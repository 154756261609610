import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeModal } from '../../slices/modals';
import WindowDimensions from '../../utilities/windowDimenssions';
import emptyImage from '../../assets/img/empty-image.jpeg';
import './_index.scss';

function ProductSearchCard({ data, handleCloseModalDesktop, productCardRef }) {
  const { name, description, slug, price, image, code } = data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = WindowDimensions();
  const isMobile = width < 768;

  return (
    <div
      className="product-search-card"
      ref={productCardRef}
      onClick={() => {
        dispatch(closeModal('search-modal'));
        handleCloseModalDesktop && handleCloseModalDesktop();
        navigate(`/product/${code}`);
      }}
    >
      <div className="img-wrapper">
        <img src={image || emptyImage} />
      </div>
      <div className="card-content">
        <p className="title">{name}</p>
        <p className="description">{description}</p>
        <p className="price">{price}</p>
      </div>
    </div>
  );
}

export default ProductSearchCard;
