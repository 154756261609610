import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { toast } from "react-toastify";
const initialState = {
  newsLetterState: "idle",
};

export const addEmailToNewsLetter = createAsyncThunk(
  "newsletter/addEmailToNewsLetter",
  async (value, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/v2/shop/newsletter-subscriptions/add-email-to-newsletter/`,
        value,
        {
          headers: {
            "Accept-Language": "en_US",
          },
        }
      );

      data = await response.data;
      if (response.status === 201) {
        toast.success("Email ajouté avec succès");
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.detail ? error?.detail : error?.detail);
    }
  }
);

const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {},
  extraReducers: {
    [addEmailToNewsLetter.pending]: (state) => {
      state.newsLetterState = "loading";
    },
    [addEmailToNewsLetter.fulfilled]: (state, action) => {
      state.newsLetterState = "succeeded";
    },
    [addEmailToNewsLetter.rejected]: (state, action) => {
      state.newsLetterState = "failed";
    },
  },
});

export const { reducer: newsletterReducer } = newsletterSlice;
