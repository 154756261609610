import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../utilities/axios';

const initialState = {
  auctionState: '',
  auction: [],
  addFollowState: '',
  followResult: [],
  deleteFollowstate: '',
  unFollowResult: [],
  participateState: '',
  participateResult: [],
  checkState: '',
  checkResult: [],
  fOllowedAuctionState: '',
  myFollowedAuction: [],
  biddedAuctionState: '',
  myBiddedAuction: [],
  winnedAuctionState: '',
  winnedAuction: [],
};

export const createBid = createAsyncThunk('auction/createNewBid', async (auction, thunkAPI) => {
  try {
    const response = await axios.post(
      '/api/v2/shop/auction-plugin/bid-an-auction/',
      {
        auctionCode: auction.auctionCode,
        price: auction.price,
      },
      {
        headers: {
          'Accept-Language': 'en_US',
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      let data = response.data;
      toast.success(data?.message);
      return data;
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.log(error, 'ERROR');
    const message = error?.detail || error.message || 'Something went wrong';
    toast.error(message);
    return thunkAPI.rejectWithValue({ message });
  }
});

export const followAuction = createAsyncThunk('auction/followBid', async (auction, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(
      `/api/v2/shop/auction-plugin/follow-an-auction/`,
      {
        auctionCode: auction.auctionCode,
      },
      {
        headers: {
          'Accept-Language': 'en_US',
        },
      }
    );

    data = await response.data;
    let toastMessage = toast.success(data?.message);
    if (response.status === 201 || 200) {
      return { data, toastMessage };
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message = error?.detail.toString();
    thunkAPI.dispatch(toast.error(message));
  }
});
export const unfollowAuction = createAsyncThunk(
  'auction/unfollowBid',
  async (auction, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/v2/shop/auction-plugin/unfollow-an-auction/`,
        {
          auctionCode: auction.auctionCode,
        },
        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const participateAuction = createAsyncThunk(
  'auction/participateAuction',
  async (auction, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/v2/shop/auction-plugin/procced-to-participate-via-stripe/`,
        {
          auctionCode: auction.auctionCode,
          price: auction.price,
        },
        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const checkparticipateAuction = createAsyncThunk(
  'auction/checkParticipate',
  async (auction, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/v2/shop/stripe/check-participate-via-stripe/`,
        {
          auctionCode: auction.auctionCode,
        },
        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const getmyFollowAuctions = createAsyncThunk(
  'auction/my-followed-auctions',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/api/v2/shop/auction-plugin/my-followed-auctions`,

        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const getmyBiddedAuctions = createAsyncThunk(
  'auction/my-bidded-auctions',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/api/v2/shop/auction-plugin/my-bidded-auctions`,

        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const getWinnedAuctions = createAsyncThunk(
  'auction/my-winned-auctions',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/api/v2/shop/auction-plugin/my-winned-auctions`,

        {
          headers: {
            'Accept-Language': 'en_US',
          },
        }
      );

      data = await response.data;
      let toastMessage = toast.success(data?.message);
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

const slice = createSlice({
  name: 'auction',
  initialState: {
    auction: {
      isFollowed: false,
      code: null,
      // other auction properties...
    },
    // other state properties...
  },

  extraReducers: {
    [createBid.pending]: (state) => {
      state.auctionState = 'loading';
    },
    [createBid.fulfilled]: (state, action) => {
      state.auctionState = 'succeeded';
      //   state.allCustomerAddresses = state.allCustomerAddresses.concat(action.payload.data);
    },
    [createBid.rejected]: (state) => {
      state.auctionState = 'failed';
    },

    [followAuction.pending]: (state) => {
      state.addFollowState = 'loading';
    },
    [followAuction.fulfilled]: (state, action) => {
      state.addFollowState = 'succeeded';
      state.auction.isFollowed = true;
      //   state.allCustomerAddresses = state.allCustomerAddresses.concat(action.payload.data);
    },
    [followAuction.rejected]: (state) => {
      state.addFollowState = 'failed';
    },
    [unfollowAuction.pending]: (state) => {
      state.deleteFollowstate = 'loading';
    },
    [unfollowAuction.fulfilled]: (state, action) => {
      state.deleteFollowstate = 'succeeded';

      state.auction.isFollowed = false;
      //   state.allCustomerAddresses = state.allCustomerAddresses.concat(action.payload.data);
    },
    [unfollowAuction.rejected]: (state) => {
      state.deleteFollowstate = 'failed';
    },

    [participateAuction.pending]: (state) => {
      state.participateState = 'loading';
    },
    [participateAuction.fulfilled]: (state, action) => {
      state.participateState = 'succeeded';

      state.participateResult = action.payload.data;
      //   state.allCustomerAddresses = state.allCustomerAddresses.concat(action.payload.data);
    },
    [participateAuction.rejected]: (state) => {
      state.participateState = 'failed';
    },

    [checkparticipateAuction.pending]: (state) => {
      state.checkState = 'loading';
    },
    [checkparticipateAuction.fulfilled]: (state, action) => {
      state.checkState = 'succeeded';

      state.checkResult = action.payload.data;
    },
    [checkparticipateAuction.rejected]: (state) => {
      state.checkState = 'failed';
    },

    [getmyFollowAuctions.pending]: (state) => {
      state.fOllowedAuctionState = 'loading';
    },
    [getmyFollowAuctions.fulfilled]: (state, action) => {
      state.fOllowedAuctionState = 'succeeded';
      state.myFollowedAuction = action.payload.data;
    },
    [getmyFollowAuctions.rejected]: (state) => {
      state.fOllowedAuctionState = 'failed';
    },

    [getmyBiddedAuctions.pending]: (state) => {
      state.biddedAuctionState = 'loading';
    },
    [getmyBiddedAuctions.fulfilled]: (state, action) => {
      state.biddedAuctionState = 'succeeded';
      state.myBiddedAuction = action.payload.data;
    },
    [getmyBiddedAuctions.rejected]: (state) => {
      state.biddedAuctionState = 'failed';
    },

    [getWinnedAuctions.pending]: (state) => {
      state.winnedAuctionState = 'loading';
    },
    [getWinnedAuctions.fulfilled]: (state, action) => {
      state.winnedAuctionState = 'succeeded';
      state.winnedAuction = action.payload.data;
    },
    [getWinnedAuctions.rejected]: (state) => {
      state.winnedAuctionState = 'failed';
    },
  },
});

export const reducer = slice.reducer;

export default slice;
