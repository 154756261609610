import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import StyleToObject from 'style-to-object';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { openModal } from '../../slices/modals';
import { getMenuItems, getSocialMediaItems } from '../../slices/home';
import { createBasket, getBasket } from '../../slices/order';
import { searchProducts } from '../../slices/products';
import WindowDimensions from '../../utilities/windowDimenssions';
import useDebounce from '../../hooks/useDebounce';
import MenuPopoverDesktop from '../../components/MenuPopoverDesktop';
import ProductSearchCard from '../../components/ProductSearchCard';
import useAuth from '../../hooks/useAuth';
import userCertification from './../../assets/img/icons/feather-user.svg';
import shoppingBag from './../../assets/img/icons/bag.svg';
import searchIcon from './../../assets/img/icons/search.svg';
import commande from './../../assets/img/icons/Commande.svg';
import logo from './../../assets/img/icons/logoBrandCollector.svg';
import menu from './../../assets/img/icons/menu.png';
import favorisIcon from './../../assets/img/icons/feather-heart.svg';
import Facebook from './../../assets/img/icons/facebookIcon.svg';
import Instagram from './../../assets/img/icons/instagramIcon.svg';
import Twitter from './../../assets/img/icons/twitterIcon.svg';
import deconnexion from './../../assets/img/icons/Deconnexion.svg';
import './_index.scss';


const HeaderProduct = () => {
  const tokenValue = localStorage.getItem('tokenValue');
  const { filtredUserData, isAuthenticated, logout } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    !tokenValue && dispatch(createBasket());
  }, [tokenValue, dispatch]);

  const { width } = WindowDimensions();
  const isMobile = width < 768;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearchText] = useState('');
  const [listWithChildren, setListWithChildren] = useState([]);
  const [listWithoutChildren, setListWithoutChildren] = useState([]);
  const [sortedMenuChildren, setSortedMenuChildren] = useState([]);
  const open = Boolean(anchorEl);
  const socialMediaItems = useSelector((state) => state.home.socialMediaItems);
  const menuItems = useSelector((state) => state.home.menuItems);
  const { allSearchedProducts, searchProductsStatus } = useSelector((state) => state.products);
  const { basket } = useSelector((state) => state.order);
  const location = useLocation();
  const searchCardRef = useRef();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkToProfile = () => {
    navigate('/profile');
    setAnchorEl(null);
  };
  const linkToAuctions = () => {
    navigate('/profile/my-auctions');
    setAnchorEl(null);
  };

  const linkToOrder = () => {
    navigate('/profile/order-history');
    setAnchorEl(null);
  };

  const linkToFavorites = () => {
    if (isAuthenticated) {
      navigate('/profile/favorites');
    } else {
      navigate('/sign-in');
    }
  };
  const handleOpenSearch = () => {
    if (isMobile) {
      dispatch(openModal('search-modal'));
    } else setOpenSearch(true);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  useDebounce(
    () => {
      search.length > 2 &&
        dispatch(searchProducts({ query: search })).then((res) => {
          // if (res?.error) {
          //   toast.error(res?.error?.message);
          //   return;
          // }
        });
    },
    1000,
    [search]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!searchCardRef?.current?.contains(event.target)) {
        setOpenSearch(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchCardRef]);

  const handleLogout = () => {
    logout();
    handleClose();
    toast.success('successfully disconnected');
  };
  const handleLogin = () => {
    navigate('/sign-in');
    handleClose();
  };

  const navigation = () => {
    navigate('/recherche/' + search);
    setOpenSearch(false);
  };
  useEffect(() => {
    localStorage.getItem('tokenValue') &&
      dispatch(getBasket()).then(
        (res) => res.meta.requestStatus === 'rejected' && localStorage.removeItem('tokenValue')
      );
  }, []);

  useEffect(() => {
    if (openSearch && search.length !== 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openSearch, search]);

  useEffect(() => {
    dispatch(getSocialMediaItems());
    dispatch(getMenuItems());
  }, []);

  useEffect(() => {
    let listWithItems = [];
    let listWithoutItems = [];
    let sortedMenuItems = [];

    sortedMenuItems = menuItems.firstLevelItems
      ?.slice()
      .sort((i1, i2) => (i1.position < i2.position ? -1 : 1));

    menuItems.firstLevelItems?.map((menuItem) =>
      menuItem.items.length === 0 ? listWithoutItems.push(menuItem) : listWithItems.push(menuItem)
    );

    setSortedMenuChildren(sortedMenuItems);
    setListWithoutChildren(listWithoutItems);
    setListWithChildren(listWithItems);
  }, [menuItems]);
  const desiredSocialMediaNames = ['Facebook', 'Instagram', 'Twitter'];
  const filteredSocialMedia =
    socialMediaItems &&
    socialMediaItems?.filter((item) => desiredSocialMediaNames?.includes(item.name));

  const itemNew = [
    {
      items: [],

      position: 5,

      style:
        ' color:black; fontSize:13px; lineHeight:16px;fontFamily:Montserrat,regular;paddingTop:5px;paddingBottom:15px  ',

      code: null,

      images: [
        {
          fullPath: '/media/image/56/d7/5f7f1571c7b9f54e959d4ae20851.png',

          type: 'Mobile',
        },
      ],

      label: 'New Arrivals',

      translation: { url: 'categories/newarrival', label: 'Promo', locale: 'fr_FR' },
    },
  ];
  const quantityOfProductsInCart = (basket?.items || []).reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );

  return (
    <div className="header">
      {!isMobile && (
        <div className="top-header">
          <div className="container top-header-wrapper">
            <div className="social-media"></div>
            <div className="client-service">
              <span> All our items are authenticated and delivered with certificates</span>
            </div>
            <div className="navigation">
              {filteredSocialMedia?.map((socialItem, index) => {
                return (
                  <div key={index}>
                    {
                      {
                        'Facebook': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Facebook} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                        'Twitter': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Twitter} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                        'Instagram': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Instagram} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                      }[socialItem.name]
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="middle-header">
        <div className="container middle-header-wrapper">
          <div className="logo-wrapper">
            {isMobile && (
              <div className="menu" onClick={() => dispatch(openModal('menu-modal'))}>
                <img src={menu} alt="menu" />
              </div>
            )}
            <div className="logo">
              <Link to="/">
                <img className="logo-img" src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          {(location?.pathname === '/' || !isMobile) && (
            <div ref={searchCardRef} className="search" onClick={handleOpenSearch}>
              <input
                type="search"
                name="search"
                placeholder="Rechercher..."
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                disabled={isMobile}
              />
              <div
                className="search-icon"
                onClick={() => {
                  navigation();
                  setOpenSearch(false);
                }}
              >
                {' '}
                <img src={searchIcon} alt="search" />
              </div>
              <div className={`search-result ${openSearch && search.length > 2 && 'active'}`}>
                <div
                  className="voir-tous"
                  onClick={() => {
                    navigation();
                    setOpenSearch(true);
                  }}
                >
                  Voir tout
                </div>
                <div className="search-result__cards">
                  {openSearch &&
                    allSearchedProducts?.length > 0 &&
                    searchProductsStatus === 'succeeded' &&
                    allSearchedProducts?.map((product, index) => (
                      <ProductSearchCard
                        key={index}
                        data={product}
                        handleCloseModalDesktop={() => setSearchText('')}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}

          <div className="actions-wrapper__middle-header">
            {location?.pathname !== '/' && isMobile && (
              <div
                className="action__middle-header"
                onClick={() => dispatch(openModal('search-modal'))}
              >
                <img src={searchIcon} alt="search" />
              </div>
            )}

            <div className="favoris-list">
              <div className="action__middle-header" onClick={() => linkToFavorites()}>
                <div className="shopping-bag-wrapper">
                  <img src={favorisIcon} alt="shopping bag" />
                </div>
                <p></p>
              </div>
            </div>
            <div>
              <button
                className="action__middle-header"
                onClick={
                  isAuthenticated
                    ? isMobile
                      ? () => navigate('/profile')
                      : handleClick
                    : () => navigate('/sign-in')
                }
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <img src={userCertification} alt="user certification" />
                <p>{filtredUserData ? 'Hello' + ' ' + filtredUserData.firstName : ''}</p>
              </button>

              <Menu
                PaperProps={{
                  style: {
                    width: 196,
                    padding: '0 4px',
                    marginTop: 16,
                    boxShadow: '0px 2px 5px #00000029',
                  },
                }}
                id="profile-menu-logged"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToProfile}>
                    <img src={userCertification} alt="user certification" />
                    <p className="menu-item-text">My account</p>
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToOrder}>
                    <img src={commande} alt="user certification" />
                    <p className="menu-item-text">My orders</p>
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToAuctions}>
                    <img src={commande} alt="user certification" />
                    <p className="menu-item-text">My auctions</p>
                  </MenuItem>
                )}
                {isAuthenticated && <Divider />}
                {isAuthenticated && (
                  <MenuItem
                    className="menu-item-header-profile"
                    onClick={isAuthenticated ? handleLogout : handleLogin}
                  >
                    <img src={deconnexion} alt="user certification" />
                    <p className="menu-item-text">{isAuthenticated ? 'Logout' : ''}</p>
                  </MenuItem>
                )}
              </Menu>
            </div>

            <div
              className="action__middle-header"
              onClick={() => {
                dispatch(openModal('basket-modal'));
              }}
            >
              <div className="shopping-bag-wrapper">
                <div className="count">{quantityOfProductsInCart || 0}</div>
                <img src={shoppingBag} alt="shopping bag" />
              </div>
            </div>
          </div>
        </div>
        {openSearch && search.length > 2 && (
          <div className="search-result-wrapper" onClick={handleCloseSearch}></div>
        )}
      </div>
      {!isMobile && (
        <div className="bottom-header">
          <div className="nav container">
            {itemNew.map((listItem, index) => (
              <NavLink
                key={index}
                style={StyleToObject(listItem.style)}
                to={
                  listItem?.translation?.url.includes('categories')
                    ? `/categories${listItem?.translation?.url?.split('categories')[1]}`
                    : listItem?.translation?.url
                }
              >
                {listItem.label}
              </NavLink>
            ))}
            {sortedMenuChildren?.map((listItem, index) =>
              listWithChildren?.includes(listItem) ? (
                <MenuPopoverDesktop
                  key={index}
                  title={listItem.label}
                  image={listItem.images.find((img) => img?.type === 'Desktop')?.fullPath}
                  secondLevelItems={listItem.items}
                  linkFirstLevel={
                    listItem?.translation?.url?.includes('categories')
                      ? '/categories' + listItem.translation?.url?.split('/categories')[1]
                      : listItem.translation?.url
                  }
                />
              ) : listWithoutChildren?.includes(listItem) ? (
                <NavLink
                  key={index}
                  style={StyleToObject(listItem.style)}
                  to={
                    listItem?.translation?.url?.includes('categories')
                      ? '/categories' + listItem?.translation?.url?.split('/categories')[1]
                      : listItem.translation?.url
                  }
                >
                  {listItem.label}
                </NavLink>
              ) : (
                <></>
              )
            )}

            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderProduct;
