import React, { useState } from 'react';
import CustomizedWithoutCheckbox from '../Label';

import './_index.scss';
import { NavLink } from 'react-router-dom';

function GroupCheckboxFilter({
  checkboxArray,
  hasShowMore,
  filterList,
  setFilterList,
  params,
  setParams,
  name,
}) {
  const [showenBrand, setShowenBrand] = useState(5);

  return (
    <div className="group-checkbox-filter">
      {checkboxArray?.slice(0, showenBrand).map((checkbox, index) => {
        return (
          <NavLink to={`/categories/${checkbox.slug}`} key={index}>
            <CustomizedWithoutCheckbox key={index} label={checkbox.name} value={checkbox.slug} />
          </NavLink>
        );
      })}
      {hasShowMore && checkboxArray?.length > showenBrand && (
        <p
          className="show-more"
          onClick={() =>
            setShowenBrand(showenBrand < checkboxArray?.length ? checkboxArray?.length : 5)
          }
        >
          {showenBrand < checkboxArray?.length ? 'More' : 'Less'}
        </p>
      )}
    </div>
  );
}

export default GroupCheckboxFilter;
