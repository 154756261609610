import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  getPageCmsState: 'idle',
  pageCms: {},
  getPagesCmsState: 'idle',
  pagesCms: [],
};

export const getPageCms = createAsyncThunk('cmsPages/cmsPages', async ({ slug }, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/cms-plugin/page-by-slug/${slug}`, {
      headers: {
        'Accept-Language': 'en_US',
      },
    });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});
export const getPagesCms = createAsyncThunk(
  'getAllPages/cmsPages',
  async ({ sectionCode }, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/cms-plugin/pages`, {
        headers: {
          'Accept-Language': 'en_US',
        },
        params: {
          'sections.code[]': sectionCode, // Query parameter for section code
          page: 1, // Example: including page and itemsPerPage
          itemsPerPage: 30,
        },
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'cmsPage',
  initialState,
  reducers: {},
  extraReducers: {
    [getPageCms.pending]: (state) => {
      state.getPageCmsState = 'loading';
    },
    [getPageCms.fulfilled]: (state, action) => {
      state.getPageCmsState = 'succeeded';
      state.pageCms = action.payload;
    },
    [getPageCms.rejected]: (state, action) => {
      state.getPageCmsState = 'failed';
    },

    [getPagesCms.pending]: (state) => {
      state.getPagesCmsState = 'loading';
    },
    [getPagesCms.fulfilled]: (state, action) => {
      state.getPagesCmsState = 'succeeded';
      state.pagesCms = action.payload;
    },
    [getPagesCms.rejected]: (state, action) => {
      state.getPagesCmsState = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export default slice;
