import React, { useState } from 'react';
import './_index.scss';
import CustomizedCheckbox from '../Checkbox';

function GroupRadioFilterByColor({
  data,
  filterList,
  setFilterList,
  params,
  setParams,
  name,
  hasShowMore,
  attributeCode,
}) {
  const [showenBrand, setShowenBrand] = useState(5);
  const [checkArray, setCheckArray] = React.useState([]);

  const handleCodeChange = (code, value) => {
    const existingIndex = checkArray.findIndex(
      (item) => item.code === code && item.value === value
    );
    if (existingIndex !== -1) {
      // If already selected, deselect it
      setCheckArray((prevCheckArray) =>
        prevCheckArray.filter((_, index) => index !== existingIndex)
      );
      setParams((prevParams) => ({
        ...prevParams,
        attributes: prevParams.attributes.filter(
          (attr) => !(attr.code === code && attr.value === value)
        ),
      }));
    } else {
      // If not selected, select it
      const updatedAttributes = {
        code: attributeCode,
        value: value,
      };
      setCheckArray((prevCheckArray) => [...prevCheckArray, updatedAttributes]);
      setParams((prevParams) => ({
        ...prevParams,
        attributes: [...(prevParams.attributes || []), updatedAttributes],
      }));
    }
  };
  return (
    <div className="group-checkbox-filter-by-color">
      {data &&
        data
          ?.slice(0, showenBrand)
          ?.map((color, index) => (
            <CustomizedCheckbox
              data={color}
              key={index}
              label={color?.value}
              value={color?.value}
              isChecked={color?.isSelectedForFilter === 'true'}
              handleInputChange={() => handleCodeChange(attributeCode, color?.value)}
            />
          ))}

      {hasShowMore && data.length > showenBrand && (
        <p
          className="show-more"
          onClick={() => setShowenBrand(showenBrand < data.length ? data.length : 5)}
        >
          {showenBrand < data?.length ? 'More' : 'Less'}
        </p>
      )}
    </div>
  );
}

export default GroupRadioFilterByColor;
