import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../utilities/axios';
import axiosForPatch from '../utilities/axiosForPatch';

const tokenValue = localStorage.getItem('tokenValue');

const initialState = {
  getAllCustomerOrdersState: 'idle',
  AllCustomerOrders: [],
  detailsCustomerOrder: [],
  getDetailsCustomerOrderStatus: 'idle',
  paymentMethod: [],
  shipmentMethod: [],
  ordersMobile: [],
  allOrdersPagination: {},
  createBasketStatus: 'idle',
  createBasketSuccess: '',
  createBasketError: '',

  productsInOrder: [],
  productsInOrderStatus: 'idle',
  productsInOrderSuccess: '',
  productsInOrderError: '',

  basket: {},
  getBasketStatus: 'idle',
  getBasketSuccess: '',
  getBasketError: '',

  addItemToBasketStatus: 'idle',
  addItemToBasketSuccess: '',
  addItemToBasketError: '',

  deleteProductInOrderStatus: 'idle',

  updateQuantityOfOrderItemStatus: 'idle',

  editAddressesCartToGivenLocationStatus: 'idle',
  editAddressesCartToGivenLocationError: '',

  editShippingCartStatus: 'idle',
  editShippingCartError: '',

  editShopInCartStatus: 'idle',
  editShopInCartError: '',

  editPaymentCartStatus: 'idle',
  editPaymentCartError: '',

  addPromoCodeStatus: 'idle',
  addPromoCodeError: '',

  productsHighlightSlug: '',
  allProductsHighlight: [],
  allProductsHighlightStatus: 'idle',
  allProductsHighlightSuccess: '',
  allProductsHighlightError: '',
  productsHighlightBySlug: {},
  productsHighlightBySlugStatus: 'idle',
};

export const getAllCustomerOrders = createAsyncThunk(
  'order/getAllCustomerOrders',
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/orders`, {
        params: query,
      });

      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetailsCustomerOrder = createAsyncThunk(
  'order/getDetailsCustomerOrder',
  async (orderTokenValue, thunkAPI) => {
    let data;
    let paymentData;
    let shipmentsData;
    try {
      const response = await axios.get(`/api/v2/shop/orders/${orderTokenValue}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createBasket = createAsyncThunk('order/createBasket', async (basketData, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/api/v2/shop/orders`, {});

    data = await response.data;
    if (response.status === 201) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(toast.error(message));

    return thunkAPI.rejectWithValue(message);
  }
});

export const getBasket = createAsyncThunk('order/getBasket', async (_, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/orders/${localStorage.getItem('tokenValue')}`);
    data = await response.data;

    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (error) {
    return Promise.reject(error?.detail);
  }
});

export const addItemToBasket = createAsyncThunk(
  'order/addItemToBasket',

  async (basketData, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/items`,
        basketData
      );

      data = response.data;

      if (response.status === 201) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.detail ? error?.detail : error?.detail);
    }
  }
);
export const updateQuantityOfOrderItem = createAsyncThunk(
  'order/updateQuantityOfOrderItem',
  async (productData, thunkAPI) => {
    const { id, quantity } = productData;
    let data;
    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/items/${id}`,
        {
          quantity,
        }
      );

      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.message ? error?.message : data?.message);
    }
  }
);

export const getAllProductsInOrder = createAsyncThunk(
  'order/getAllProductsInOrder',
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/items`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteProductInOrder = createAsyncThunk(
  'order/deleteProductInOrder',
  async (itemId, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/items/${itemId}`
      );
      data = await response.data;
      if (response.status === 204) {
        thunkAPI.dispatch(getBasket());
        thunkAPI.dispatch(toast.success('Product removed'));
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editAddressesCartToGivenLocation = createAsyncThunk(
  'order/editAddressesCartToGivenLocation',
  async (addressData, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}`,
        addressData
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.detail ? error?.detail : error?.detail);
    }
  }
);
export const addPromoCode = createAsyncThunk('order/addPromoCode', async (promoData, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(
      `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}`,
      promoData
    );
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    return Promise.reject(error?.detail);
  }
});

export const editShippingCart = createAsyncThunk(
  'order/editShippingCart',
  async (shippingData, thunkAPI) => {
    let data;
    const { id, shippingMethod } = shippingData;
    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/shipments/${id}`,
        { shippingMethod }
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail
      );
    }
  }
);

export const editShopInCart = createAsyncThunk(
  'order/editShopInCart',
  async (storeId, thunkAPI) => {
    let data;
    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/store/${storeId}`,
        {}
      );

      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.message ? error?.message : data?.message);
    }
  }
);

export const editPaymentCart = createAsyncThunk(
  'order/editPaymentCart',
  async (paymentData, thunkAPI) => {
    let data;
    const { id, paymentMethod } = paymentData;
    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/payments/${id}`,
        { paymentMethod }
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail
      );
    }
  }
);
export const editCompleteCheckout = createAsyncThunk(
  'order/editCompleteCheckout',
  async (notes, thunkAPI) => {
    let data;
    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/orders/${localStorage.getItem('tokenValue')}/complete`,
        notes
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.detail ? error?.response?.data?.detail : error?.detail
      );
    }
  }
);

const slice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    emptyTheBasket: (state, action) => {
      if (!tokenValue) {
        state.basket = {};
      }
    },
    resetOrdersInMobile: (state) => {
      state.ordersMobile = [];
    },
  },
  extraReducers: {
    [createBasket.pending]: (state) => {
      state.createBasketStatus = 'loading';
    },
    [createBasket.fulfilled]: (state, action) => {
      state.createBasketStatus = 'succeeded';
      localStorage.setItem('tokenValue', action.payload.tokenValue);
      state.basket = action.payload;
    },
    [createBasket.rejected]: (state, action) => {
      state.createBasketStatus = 'failed';
      state.createBasketError = action.payload;
    },
    [getBasket.pending]: (state) => {
      state.getBasketStatus = 'loading';
    },
    [getBasket.fulfilled]: (state, action) => {
      state.getBasketStatus = 'succeeded';
      state.basket = action.payload;
    },
    [getBasket.rejected]: (state, action) => {
      state.getBasketStatus = 'failed';
      state.getBasketError = action.payload;
    },
    [addItemToBasket.pending]: (state) => {
      state.addItemToBasketStatus = 'loading';
    },
    [addItemToBasket.fulfilled]: (state, action) => {
      state.addItemToBasketStatus = 'succeeded';
      state.basket = action.payload;
    },
    [addItemToBasket.rejected]: (state, action) => {
      state.addItemToBasketStatus = 'failed';
      state.addItemToBasketError = action.payload;
    },
    [getAllProductsInOrder.pending]: (state) => {
      state.productsInOrderStatus = 'loading';
    },
    [getAllProductsInOrder.fulfilled]: (state, action) => {
      state.productsInOrderStatus = 'succeeded';
      state.productsInOrder = action.payload;
    },
    [getAllProductsInOrder.rejected]: (state, action) => {
      state.productsInOrderStatus = 'failed';
    },
    [deleteProductInOrder.pending]: (state) => {
      state.deleteProductInOrderStatus = 'loading';
    },
    [deleteProductInOrder.fulfilled]: (state, action) => {
      state.deleteProductInOrderStatus = 'succeeded';
    },
    [deleteProductInOrder.rejected]: (state, action) => {
      state.deleteProductInOrderStatus = 'failed';
    },
    [updateQuantityOfOrderItem.pending]: (state) => {
      state.updateQuantityOfOrderItemStatus = 'loading';
    },
    [updateQuantityOfOrderItem.fulfilled]: (state, action) => {
      state.updateQuantityOfOrderItemStatus = 'succeeded';
      state.basket = action.payload;
    },
    [updateQuantityOfOrderItem.rejected]: (state, action) => {
      state.updateQuantityOfOrderItemStatus = 'failed';
    },
    [editAddressesCartToGivenLocation.pending]: (state) => {
      state.editAddressesCartToGivenLocationStatus = 'loading';
    },
    [editAddressesCartToGivenLocation.fulfilled]: (state, action) => {
      state.editAddressesCartToGivenLocationStatus = 'succeeded';
      state.basket = action.payload;
    },
    [editAddressesCartToGivenLocation.rejected]: (state, action) => {
      state.editAddressesCartToGivenLocationStatus = 'failed';
      state.editAddressesCartToGivenLocationError = action.payload;
    },
    [addPromoCode.pending]: (state) => {
      state.addPromoCodeStatus = 'loading';
    },
    [addPromoCode.fulfilled]: (state, action) => {
      state.addPromoCodeStatus = 'succeeded';
      state.basket = action.payload;
    },
    [addPromoCode.rejected]: (state, action) => {
      state.addPromoCodeStatus = 'failed';
      state.addPromoCodeError = action.payload;
    },
    [editShippingCart.pending]: (state) => {
      state.editShippingCartStatus = 'loading';
    },
    [editShippingCart.fulfilled]: (state, action) => {
      state.editShippingCartStatus = 'succeeded';
      state.basket = action.payload;
    },
    [editShippingCart.rejected]: (state, action) => {
      state.editShippingCartStatus = 'failed';
      state.editShippingCartError = action.payload;
    },
    [editShopInCart.pending]: (state) => {
      state.editShopInCartStatus = 'loading';
    },
    [editShopInCart.fulfilled]: (state, action) => {
      state.editShopInCartStatus = 'succeeded';
      state.basket = action.payload;
    },
    [editShopInCart.rejected]: (state, action) => {
      state.editShopInCartStatus = 'failed';
      state.editShopInCartError = action.payload;
    },
    [editPaymentCart.pending]: (state) => {
      state.editPaymentCartStatus = 'loading';
    },
    [editPaymentCart.fulfilled]: (state, action) => {
      state.editPaymentCartStatus = 'succeeded';
      state.basket = action.payload;
    },
    [editPaymentCart.rejected]: (state, action) => {
      state.editPaymentCartStatus = 'failed';
      state.editPaymentCartError = action.payload;
    },
    [editCompleteCheckout.pending]: (state) => {
      state.editCompleteCheckoutStatus = 'loading';
    },
    [editCompleteCheckout.fulfilled]: (state, action) => {
      state.editCompleteCheckoutStatus = 'succeeded';
      state.basket = action.payload;
    },
    [editCompleteCheckout.rejected]: (state, action) => {
      state.editCompleteCheckoutStatus = 'failed';
      state.editCompleteCheckoutError = action.payload;
    },
    [getAllCustomerOrders.pending]: (state) => {
      state.getAllCustomerOrdersState = 'loading';
    },
    [getAllCustomerOrders.fulfilled]: (state, action) => {
      state.getAllCustomerOrdersState = 'succeeded';
      state.AllCustomerOrders = action.payload.data;
      state.ordersMobile = [...state.ordersMobile, ...action.payload.data];
      state.allOrdersPagination = action.payload.pagination;
    },
    [getAllCustomerOrders.rejected]: (state) => {
      state.getAllCustomerOrdersState = 'failed';
    },
    [getDetailsCustomerOrder.pending]: (state) => {
      state.getDetailsCustomerOrderStatus = 'loading';
    },
    [getDetailsCustomerOrder.fulfilled]: (state, action) => {
      state.getDetailsCustomerOrderStatus = 'succeeded';
   
      state.detailsCustomerOrder = action.payload;
      state.paymentMethod = action.payload.paymentData;
      state.shipmentMethod = action.payload.shipmentsData;
    },
    [getDetailsCustomerOrder.rejected]: (state) => {
      state.getDetailsCustomerOrderStatus = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const { emptyTheBasket, resetOrdersInMobile } = slice.actions;

export default slice;
