import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './_index.scss';

function PriceInputRange({ min, max, params, setParams, name }) {
  const [inputRange, setInputRange] = useState({
    min: min,
    max: max,
  });
  useEffect(() => {
    setInputRange({
      min: min,
      max: max,
    });
  }, [min, max]);

  return (
    <div className="price-input-range">
      <InputRange
        draggableTrack={false}
        formatLabel={(value) => value?.toLocaleString('en-US') + ' EUR'}
        maxValue={max}
        minValue={min}
        onChange={(value) => setInputRange({ ...value })}
        onChangeComplete={(value) => setParams({ ...params, [name]: value })}
        value={inputRange}
      />
      <div className="range">
        <p>{inputRange?.min?.toLocaleString('en-US') + ' EUR'}</p>
        <p>{inputRange?.max?.toLocaleString('en-US') + ' EUR'}</p>
      </div>
    </div>
  );
}

export default PriceInputRange;
