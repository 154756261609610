import { Suspense, Fragment, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import AuthGuard from './components/AuthGuard';
import OrderGuard from './components/OrderGuard';
import Layout from './layouts/MainLayout';
import HomeLayout from './layouts/HomeLayout';
import LoadingScreen from './components/LoadingScreen';

export const RenderRoutes = ({ routes = [] }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? <RenderRoutes routes={route.routes} /> : <Element />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    layout: HomeLayout,
    guard: GuestGuard,
    path: '/sign-in',
    element: lazy(() => import('./pages/Auth/SignIn')),
  },
  {
    exact: true,
    layout: HomeLayout,
    guard: GuestGuard,
    path: '/new-sign-up',
    element: lazy(() => import('./pages/Auth/newSignUp')),
  },
  {
    exact: true,
    layout: HomeLayout,
    guard: GuestGuard,
    path: '/sign-up',
    element: lazy(() => import('./pages/Auth/SignUp')),
  },
  {
    exact: true,
    layout: HomeLayout,
    guard: GuestGuard,
    path: '/forgot-password',
    element: lazy(() => import('./pages/Auth/ForgotPassword')),
  },
  {
    exact: true,
    layout: HomeLayout,
    guard: GuestGuard,
    path: '/forgotten-password/:resetPasswordToken',
    element: lazy(() => import('./pages/Auth/ResetPassword')),
  },
  {
    exact: true,
    layout: Layout,
    path: '/qui-sommes-nous',
    element: lazy(() => import('./pages/AboutUs')),
  },
  {
    exact: true,
    layout: Layout,
    path: '/carte-cadeau',
    element: lazy(() => import('./pages/GiftCard')),
  },
  {
    exact: true,
    layout: Layout,
    path: '/condition-generale-vente',
    element: lazy(() => import('./pages/ConditionVente')),
  },

  {
    exact: true,
    layout: Layout,
    path: '/livraison',
    element: lazy(() => import('./pages/Delivery')),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: '/nos-engagements',
    element: lazy(() => import('./pages/Engagement')),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: '/retour-rembourssement',
    element: lazy(() => import('./pages/Remboursement')),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: '/contact-us',
    element: lazy(() => import('./pages/ContactUs')),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: '/recherche/:query',
    element: lazy(() => import('./pages/Search/Category')),
  },

  {
    exact: true,
    layout: HomeLayout,
    path: '/page/:slug',
    element: lazy(() => import('./pages/CmsPages')),
  },

  {
    exact: true,
    layout: HomeLayout,
    path: '/blog',
    element: lazy(() => import('./pages/Blog')),
  },
  {
    exact: true,
    layout: HomeLayout,
    path: '/event/:slug',
    element: lazy(() => import('./pages/Event')),
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: HomeLayout,
    path: '/profile/*',
    routes: [
      {
        path: '/',
        guard: AuthGuard,
        element: lazy(() => import('./pages/Profile')),
      },
      {
        path: '/informations',
        element: lazy(() => import('./pages/Profile/Informations')),
      },
      {
        path: '/edit-password',
        element: lazy(() => import('./pages/Profile/Informations/EditPassword')),
      },
      {
        path: '/order-history',
        element: lazy(() => import('./pages/Profile/OrderHistory')),
      },
      {
        path: '/order-details/:id',
        element: lazy(() => import('./pages/Profile/OrderHistory/OrderDetails')),
      },
      {
        path: '/addresses',
        element: lazy(() => import('./pages/Profile/Addresses')),
      },
      {
        path: '/edit-address/:id',
        element: lazy(() => import('./pages/Profile/Addresses/EditAddress')),
      },
      {
        path: '/favorites',
        element: lazy(() => import('./pages/Profile/Favorites')),
      },
      {
        path: '/my-auctions',
        element: lazy(() => import('./pages/Profile/Auctions')),
      },
      {
        path: '*',
        element: lazy(() => import('./pages/Auth/NotFound')),
      },
    ],
  },

  {
    exact: true,
    guard: OrderGuard,
    path: '/tunnel-achat/*',

    routes: [
      {
        path: '/',
        element: lazy(() => import('./pages/ShoppingFunnel')),
      },
      {
        path: '/online-payment',
        element: lazy(() => import('./pages/ShoppingFunnel/components/CompleteOrder')),
      },
    ],
  },
  {
    path: '/*',
    ///guard: GuestGuard,

    routes: [
      {
        exact: true,
        path: '/',
        layout: HomeLayout,
        element: lazy(() => import('./pages/Home')),
      },
      {
        exact: true,
        path: '/profil',
        layout: HomeLayout,
        element: lazy(() => import('./pages/Profile')),
      },

      {
        exact: true,
        layout: HomeLayout,
        path: 'product/:productCode',
        element: lazy(() => import('./pages/ProductSheet')),
      },
      {
        exact: true,
        layout: HomeLayout,
        path: '/products-highlight/:productsHighlightCategory',
        element: lazy(() => import('./pages/CurrentSelectionProducts')),
      },
      {
        exact: true,
        layout: HomeLayout,
        path: '/newarrival',
        element: lazy(() => import('./pages/GetAllProducts/getAllproduct')),
      },
      {
        exact: true,
        path: '/shops/*',
        layout: Layout,
        routes: [
          {
            exact: true,
            path: '/',
            layout: HomeLayout,
            element: lazy(() => import('./pages/Shops')),
          },
          {
            exact: true,
            layout: HomeLayout,
            path: '/shop-details/:id',
            element: lazy(() => import('./pages/Shops/ShopDetails')),
          },
        ],
      },
      {
        exact: true,
        layout: HomeLayout,
        path: '</panier>',
        element: lazy(() => import('./pages/Basket')),
      },
      {
        exact: true,
        path: '/categories/*',
        layout: HomeLayout,

        element: lazy(() => import('./pages/Categories')),
        routes: [
          {
            exact: true,

            path: '/',
            element: lazy(() => import('./pages/Categories')),
          },

          {
            exact: true,
            path: '/:categoryName',

            element: lazy(() => import('./pages/Categories/Category')),
          },
          {
            exact: true,

            path: '/:categoryName/:subCategoryName',
            element: lazy(() => import('./pages/Categories/Category')),
          },
          {
            exact: true,

            path: '/:categoryName/:subCategoryName/:subSubCategoryName',
            element: lazy(() => import('./pages/Categories/Category')),
          },
        ],
      },
      {
        exact: true,
        layout: HomeLayout,

        path: '/recherche/:categoryName',
        element: lazy(() => import('./pages/Search/Category')),
      },
      {
        path: '*',
        layout: HomeLayout,
        element: lazy(() => import('./pages/Auth/NotFound')),
      },
    ],
  },
];

export default routes;
