import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ModalsProvider } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import routes, { RenderRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './utilities/ScrollToTop';
import './App.scss';

function App() {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <ScrollToTop />
        <AuthProvider>
          <RenderRoutes routes={routes} />
          <ModalsProvider />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
