import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  AllShopsState: '',
  AllShops: [],
  shopState: '',
  shopData: {},
  channelState: '',
  channelData: {},
};

export const getAllShops = createAsyncThunk('shops/getAllShops', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/stores`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getShopData = createAsyncThunk('shops/getShopData', async (slug, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/stores/${slug}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getShopInfo = createAsyncThunk('shops/getShopInfo', async (code, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/channels/${code}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const slice = createSlice({
  name: 'shops',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllShops.pending]: (state) => {
      state.AllShopsState = 'loading';
    },
    [getAllShops.fulfilled]: (state, action) => {
      state.AllShopsState = 'succeeded';
      state.AllShops = action.payload.filter((shop) => shop.enabled);
    },
    [getAllShops.rejected]: (state) => {
      state.AllShopsState = 'failed';
    },
    [getShopData.pending]: (state) => {
      state.shopState = 'loading';
    },
    [getShopData.fulfilled]: (state, action) => {
      state.shopState = 'succeeded';
      state.shopData = action.payload;
    },
    [getShopData.rejected]: (state) => {
      state.shopState = 'failed';
    },

    [getShopInfo.pending]: (state) => {
      state.channelState = 'loading';
    },
    [getShopInfo.fulfilled]: (state, action) => {
      state.channelState = 'succeeded';
      state.channelData = action.payload;
    },
    [getShopInfo.rejected]: (state) => {
      state.channelState = 'failed';
    },



  },
});

export const reducer = slice.reducer;
export const {} = slice.actions;

export default slice;
