import React, { useEffect, useState } from 'react';
import { Divider, Drawer, IconButton } from '@mui/material';
import close from './../../../assets/img/icons/close.svg';
import Filter from '../../Filter';

import './_index.scss';
import Button from '../../Button';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, removeFilterQuery } from '../../../slices/products';
import { useLocation, useSearchParams } from 'react-router-dom';

function FilterModal({ id, open, handleClose, data }) {
  const [_, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { search, location } = useLocation();

  const { allProductsStatus, allProductsFilter } = useSelector((state) => state.products);

  const [params, setParams] = useState('');
  useEffect(() => {
    dispatch(removeFilterQuery());
    setParams('');
    setSearchParams({});
  }, [dispatch, location]);
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => {
        handleClose(id);
      }}
      className="modal"
    >
      <div className="filter-modal-wrapper">
        <div className="filter-modal">
          <div className="modal-header">
            <p className="modal-title">Filter</p>
            <IconButton className="close" onClick={() => handleClose(id)}>
              <img src={close} alt="close" />
            </IconButton>
          </div>
          <Filter
            params={params}
            setParams={setParams}
            categoryName={data?.categoryName}
            currentCodecategory={data?.currentCodecategory}
            isLoading={allProductsStatus === 'loading'}
            filterData={allProductsFilter[0]}
            // hasCategory={['women', 'men', 'kids'].includes(
            //   data?.currentCodecategory.toLocaleLowerCase()
            // )}
            // hasBrand={['women', 'men', 'kids'].includes(
            //   data?.currentCodecategory.toLocaleLowerCase()
            // )}
            hasAllProduct={[]}
          />
          <Button
            text="Filtrer"
            className="btn-filter"
            onClick={() => {
              dispatch(getAllProducts(search));
              handleClose(id);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default FilterModal;
