import React, { useEffect, useState } from 'react';

import { Box, IconButton, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';
import SignUpForm from '../../Authentification/SignUpForm';
import BidForm from '../../BidForm';

function BidModal({ id, open, handleClose, data }) {
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
    borderRadius: '5px',
  };

  const formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  }).format(data?.bidValue);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
      className="review-modal"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="modal-review-wrapper">
          <p className="modal-title">Check your bid</p>
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>

          <div className="modal-content">
            <BidForm formattedNumber={formattedNumber} data={data} handleClose={handleClose} />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default BidModal;
