import React, { useState } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';

function ResetPasswordModal({ id, open, handleClose, data }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
  };

  //   Modal width
  const modalWidth = document.documentElement;
  if (data?.displaySuccessMessage) modalWidth.style.setProperty('--modalWidth', '786px');
  else modalWidth.style.setProperty('--modalWidth', '565px');

  // Modal Height
  const modalHeight = document.documentElement;
  if (data?.displaySuccessMessage) modalHeight.style.setProperty('--modalHeight', '162px');
  else modalWidth.style.setProperty('--modalHeight', '114px');

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="reset-password-title"
      aria-describedby="reset-password-description"
      className="reset-password"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="reset-password-wrapper">
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>

          {data?.displaySuccessMessage ? (
            <div className="modal-content">
              <p className="success-message">The password reset email has been sent.</p>
              <p className="message-text">
                A password reset email has been sent to the email address of your account, but it
                may take several minutes to appear in your inbox. Please wait at least 10 minutes
                before attempting another reset.
              </p>{' '}
            </div>
          ) : (
            <div className="modal-content">
              <p className="error-message">WARNING!</p>
              <p className="message-text">
                The address you provided does not match an existing account.
              </p>{' '}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ResetPasswordModal;
