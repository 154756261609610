import React from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';
import Button from '../../Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAddress,
  setNewListAddress,
  ResetSuccessDeleteAddressMessage,
} from '../../../slices/addresses';

function DeleteAddressModal({ id, open, handleClose, data }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
  };

  const dispatch = useDispatch();
  const deleteAddressById = () => {
    let AddressId = data?.id;
    dispatch(deleteAddress(AddressId));
    handleClose(id);
  };
  const success = useSelector((state) => state.addresses.deleteAddressSucess);
  if (success)
    setTimeout(() => {
      let AddressId = data?.id;
      dispatch(setNewListAddress(AddressId));
      dispatch(ResetSuccessDeleteAddressMessage());
    }, 250);

  return (
    <Modal open={open} onClose={() => handleClose(id)} disableAutoFocus={true}>
      <Box sx={style}>
        <div className="delete-address-wrapper">
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>
          <div className="modal-content">
            <p className="error-message">WARNING !</p>
            <p className="message-text">Do you really want to delete this address ? </p>{' '}
          </div>
          <div className="action-buttons-wrapper">
            <Button text="Cancel" className="action-btn cancel-btn" onClick={() => handleClose(id)} />
            <Button text="Delete" className="action-btn" onClick={() => deleteAddressById()} />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default DeleteAddressModal;
