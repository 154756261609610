import React, { useState } from 'react';

import Button from '../Button';
import { useDispatch } from 'react-redux';
import { createBid } from '../../slices/auction';
import { closeModal } from '../../slices/modals';
import './_index.scss';

function BidForm({ data, formattedNumber, handleClose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function handleConfirmBid() {
    dispatch(createBid({ auctionCode: data?.product?.auction?.code, price: data?.bidValue })).then(
      () => {
        window.location.reload();
      }
    );
  }

  return (
    <>
      <div className="sign-up-page-form-item">
        <label className="sign-up-form-label" htmlFor="lastName">
          Current bid :
        </label>
        <div className="sign-up-form-item">
          {/* <Field className="form-input" id="lastName" name="lastName" />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="error-message">{formik.errors.lastName}</div>
                ) : null} */}
          {data?.product?.auction?.formattedCurrentPrice}
        </div>
        <label className="sign-up-form-label" htmlFor="firstName">
          Your maximum bid :
        </label>
        <div className="sign-up-form-item">
          {/* <Field className="form-input" id="firstName" name="firstName" />
                
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="error-message">{formik.errors.firstName}</div>
                ) : null} */}
          {formattedNumber}
        </div>
        <label className="sign-up-form-label" htmlFor="email">
          Remaining time :
        </label>
        <div className="sign-up-form-item">
          {/* <Field className="form-input" id="email" name="email" type="email" />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-message">{formik.errors.email}</div>
                ) : null} */}
          {data?.remainingTime}
        </div>
        <label className="sign-up-form-label" htmlFor="phoneNumber">
          Delivery :
        </label>
        <div className="sign-up-form-item">
          {/* <Field
                  className="form-input"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  onChange={(e) => {
                    let newValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                    // Ensure that the prefix +33 cannot be deleted
                    if (!newValue.startsWith('+33')) {
                      // If the input doesn't start with +33, prepend it
                      newValue = '+33' + newValue;
                    }
                    // Prevent adding 33 repeatedly
                    if (newValue.startsWith('+3333')) {
                      // If there's an attempt to add 33 repeatedly, remove the extra 3
                      newValue = newValue.replace('+3333', '+33');
                    }
                    formik.setFieldValue('phoneNumber', newValue);
                  }}
                />
                

                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="error-message">{formik.errors.phoneNumber}</div>
                ) : null} */}
          {data?.priceShopping}
        </div>
        <label className="sign-up-form-label" htmlFor="password">
          Total estimated :
        </label>
        <div className="sign-up-form-item">
          {/* <Field className="form-input" id="password" name="password" type="password" />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error-message">{formik.errors.password}</div>
                ) : null} */}
          <b> {formattedNumber}</b>
        </div>
        <label className="sign-up-form-label" htmlFor="password"></label>
        <div className="sign-up-form-item">
          {' '}
          <span>*Taxes and customs duties may be charged for this item upon delivery.</span>{' '}
          <span>
            {' '}
            *Applicable sales taxes and other fees may be added upon completion of purchase.
          </span>
        </div>

        <label className="sign-up-form-label" htmlFor="password"></label>
        <div className="create-btn-bid">
          <Button
            text="Edit the bid"
            className="black"
            onClick={() => dispatch(closeModal('check-bid-modal'))}
          />
          <Button
            text="Confirm the bid"
            className="background-black"
            // isLoading={loading}
            onClick={handleConfirmBid} // Add parentheses to call the function
          />
        </div>
      </div>

      <div className="footer-section">
        <span>
          When you confirm your bid, you agree to buy this item if you are the highest bidder. It
          also means that you confirm that you have read and agree to the Terms of Use of the
          International Delivery ServiceThis page opens in a new window or tab.. Customs duties and
          taxes may vary depending on the amount of your best bid.
        </span>
      </div>
    </>
  );
}

export default BidForm;
