import React, { useEffect, useState } from 'react';

import { Box, IconButton, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import StripeIcon from './../../../assets/img/stripePay.png';
import GooglePlayIcon from './../../../assets/img/clickApplePay.png';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';
import Button from '../../Button';
import { participateAuction } from '../../../slices/auction';

function ChoosePaymentModal({ id, open, handleClose, data }) {
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
    borderRadius: '5px',
  };

  function addPaymentStripe() {
    dispatch(
      participateAuction({ auctionCode: data?.product?.auction?.code, price: data?.bidValue })
    )
      .then((response) => {
        // Assume the API response has a structure like { redirectUrl: "https://example.com/redirect" }
        const redirectUrl = response;

        // Log data and redirect URL for debugging purposes

        // Redirect to the provided URL
        if (redirectUrl) {
          window.location.href = redirectUrl?.payload?.data?.redirectLink;
        } else {
          console.error('No redirect URL provided in the response.');
        }
      })
      .catch((error) => {
        console.error('Error participating in the auction:', error);
      });
  }

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
      className="review-modal"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="modal-review-wrapper">
          <p className="modal-title">Choose the payment Method</p>
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>

          <div className="modal-content">
            <div className="added-to-card-container">
              <button className="basket-btn" onClick={() => addPaymentStripe()}>
                Pay with <img src={StripeIcon} />
              </button>
              <button className="basket-btn">
                Pay with <img src={GooglePlayIcon} />
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ChoosePaymentModal;
