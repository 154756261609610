import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { toast } from 'react-toastify';

const initialState = {
  addressState: '',
  allCustomerAddresses: [],
  allCountries: [],
  addressById: {},
  deleteAddressSucess: false,
  latest: {},
  getAddressByIdStatus: 'idle',

  getAllCustomerAddressesStatus: 'idle',
};

export const getAllCustomerAddresses = createAsyncThunk(
  'addresses/getAllCustomerAddresses',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/addresses`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAddressById = createAsyncThunk(
  'addresses/getAddressById',
  async (addressId, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/addresses/${addressId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllCountries = createAsyncThunk('addresses/getAllCountries', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/countries`);
    data = await response.data;
    if (response.status === 200 || 201) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const editAddress = createAsyncThunk(
  'addresses/editAddress',
  async (addressEditdata, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(`/api/v2/shop/addresses/${addressEditdata.addressId}`, {
        alias: addressEditdata.values.alias,
        firstName: addressEditdata.values.firstName,
        lastName: addressEditdata.values.lastName,
        phoneNumber: addressEditdata.values.phoneNumber,
        countryCode: addressEditdata.values.country,
        provinceCode: addressEditdata.provinceCode,
        provinceName: addressEditdata.values.city,
        street: addressEditdata.values.address,
        city: addressEditdata.values.addressPlus,
        postcode: addressEditdata.values.zipCode,
      });
      data = await response.data;
      if (response.status === 200 || 201) {
        thunkAPI.dispatch(toast.success('Adresse modifiée avec succès'));
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const createNewAddress = createAsyncThunk(
  'addresses/createNewAddress',
  async (newAddress, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/v2/shop/addresses`, {
        alias: newAddress.values.addressName,
        firstName: newAddress.values.firstName,
        lastName: newAddress.values.lastName,
        phoneNumber: newAddress.values.phoneNumber,
        countryCode: newAddress.values.countryCode,
        country: newAddress.values.country,

        provinceCode: newAddress.provinceCode,
        provinceName: newAddress.values.city,
        street: newAddress.values.address,
        city: newAddress.values.city,
        postcode: newAddress.values.postcode,
      });

      data = await response.data;
      let toastMessage = toast.success('Address added successufly');
      if (response.status === 201 || 200) {
        return { data, toastMessage };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const deleteAddress = createAsyncThunk(
  'addresses/deleteAddress',
  async (AddressId, thunkAPI) => {
    try {
      const response = await axios.delete(`/api/v2/shop/addresses/${AddressId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      let message = toast.success('Address deleted');
      let newList = true;

      if (response.status === 200 || 201) {
        return { message, newList };
      }
      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

const slice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setNewListAddress: (state, action) => {
      state.allCustomerAddresses = state.allCustomerAddresses.filter(
        (address) => address.id !== action.payload
      );
    },
    ResetSuccessDeleteAddressMessage: (state) => {
      state.deleteAddressSucess = false;
    },
    getQuery: (state, action) => {
      state.latest = action.payload;
    },
  },
  extraReducers: {
    [getAllCustomerAddresses.pending]: (state) => {
      state.addressState = 'loading';
      state.getAllCustomerAddressesStatus = 'loading';
    },
    [getAllCustomerAddresses.fulfilled]: (state, action) => {
      state.addressState = 'succeeded';
      state.getAllCustomerAddressesStatus = 'succeeded';
      state.allCustomerAddresses = action.payload;
    },
    [getAllCustomerAddresses.rejected]: (state) => {
      state.addressState = 'failed';
      state.getAllCustomerAddressesStatus = 'failed';
    },
    [getAllCountries.pending]: (state) => {
      state.addressState = 'loading';
    },
    [getAllCountries.fulfilled]: (state, action) => {
      state.addressState = 'succeeded';
      state.allCountries = action.payload;
    },
    [getAllCountries.rejected]: (state) => {
      state.addressState = 'failed';
    },
    [createNewAddress.pending]: (state) => {
      state.addressState = 'loading';
    },
    [createNewAddress.fulfilled]: (state, action) => {
      state.addressState = 'succeeded';
      state.allCustomerAddresses = state.allCustomerAddresses.concat(action.payload.data);
    },
    [createNewAddress.rejected]: (state) => {
      state.addressState = 'failed';
    },
    [getAddressById.pending]: (state) => {
      state.getAddressByIdStatus = 'loading';
    },
    [getAddressById.fulfilled]: (state, action) => {
      state.getAddressByIdStatus = 'succeeded';
      state.addressById = action.payload;
    },
    [getAddressById.rejected]: (state) => {
      state.getAddressByIdStatus = 'failed';
    },
    [editAddress.pending]: (state) => {
      state.addressState = 'loading';
    },
    [editAddress.fulfilled]: (state) => {
      state.addressState = 'succeeded';
    },
    [editAddress.rejected]: (state) => {
      state.addressState = 'failed';
    },
    [deleteAddress.pending]: (state) => {
      state.addressState = 'loading';
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.deleteAddressSucess = action.payload.newList;
      state.addressState = 'succeeded';
    },
    [deleteAddress.rejected]: (state) => {
      state.addressState = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const { setNewListAddress, ResetSuccessDeleteAddressMessage, getQuery } = slice.actions;

export default slice;
