import './_index.scss';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const CustomInput = ({ field, form, label, placeHolder, className, ...props }) => {
  return (
    <div className={className ? className + ' custom-input' : 'custom-input'}>
      <label className="input-label" htmlFor={field.name}>
        {label}
      </label>
      <div className="input-wrapper">
        <input {...field} {...props} id={field.name} placeholder={placeHolder} />
        {form.touched[field.name] && form.errors[field.name] && (
          <p className="input-error">{form.errors[field.name]}</p>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
