import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../Button';
import useAuth from '../../../hooks/useAuth';
import './_index.scss';

function SignUpForm() {
  const { signup } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    gender: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '+33',
    password: '',
    subscribedToNewsletter: true,
  };

  const phoneRegExp = /^(\+?33|0)(\d{9,10})$/;
  const validationSchema = Yup.object({
    gender: Yup.string().required('required'),
    firstName: Yup.string()
      .required('required')
      .matches(/^[A-Za-z ]*$/, 'Invalid first Name')
      .max(40),
    lastName: Yup.string()
      .required('required')
      .matches(/^[A-Za-z ]*$/, 'Invalid last Name')
      .max(40),
    email: Yup.string().email('Invalid email').required('required'),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Invalid phone number')
      .required('required')
      .max(14, 'Le numéro de téléphone doit comporter au maximum 10 caractères.'),
    password: Yup.string()
      .required('required')
      .min(8, 'password must be at least 8 characters long'),
  });

  const onSubmit = async (values, { resetForm }) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await signup(values);
      toast.success('Compte crée avec succès');
      navigate('/sign-in');
      resetForm();
    } catch (err) {
      toast.error(err.detail);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formik) => {
        return (
          <Form noValidate autoComplete="off">
            <div className="sign-up-page-form-item">
              <label className="sign-up-form-label" htmlFor="gender">
                Gender:
              </label>
              <div className="sign-up-form-item">
                <div role="group" aria-labelledby="my-radio-group" className="form-radio-group">
                  <label className="radio-item">
                    <Field type="radio" name="gender" value="m" />
                    Male
                  </label>
                  <label className="radio-item">
                    <Field type="radio" name="gender" value="f" />
                    Female
                  </label>
                </div>
                {formik.touched.gender && formik.errors.gender ? (
                  <div className="error-message">{formik.errors.gender}</div>
                ) : null}
              </div>
              <label className="sign-up-form-label" htmlFor="lastName">
                First name:
              </label>
              <div className="sign-up-form-item">
                <Field className="form-input" id="lastName" name="lastName" />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="error-message">{formik.errors.lastName}</div>
                ) : null}
              </div>
              <label className="sign-up-form-label" htmlFor="firstName">
                Last name:
              </label>
              <div className="sign-up-form-item">
                <Field className="form-input" id="firstName" name="firstName" />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="error-message">{formik.errors.firstName}</div>
                ) : null}
              </div>
              <label className="sign-up-form-label" htmlFor="email">
                E-mail:
              </label>
              <div className="sign-up-form-item">
                <Field className="form-input" id="email" name="email" type="email" />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-message">{formik.errors.email}</div>
                ) : null}
              </div>
              <label className="sign-up-form-label" htmlFor="phoneNumber">
                Phone:
              </label>
              <div className="sign-up-form-item">
                <Field
                  className="form-input"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  maxLength="14"
                  onChange={(e) => {
                    let newValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                    // Ensure that the prefix +33 cannot be deleted
                    if (!newValue.startsWith('33')) {
                      // If the input doesn't start with 33, prepend it
                      newValue = '33' + newValue;
                    }
                    // Prevent adding 33 repeatedly
                    if (newValue.startsWith('3333')) {
                      // If there's an attempt to add 33 repeatedly, remove the extra 3
                      newValue = newValue.replace('3333', '33');
                    }
                    // Limit the total length to 14 characters
                    if (newValue.length > 12) {
                      newValue = newValue.slice(0, 12);
                    }
                    formik.setFieldValue('phoneNumber', '+' + newValue);
                  }}
                />

                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="error-message">{formik.errors.phoneNumber}</div>
                ) : null}
              </div>
              <label className="sign-up-form-label" htmlFor="password">
                Password:
              </label>
              <div className="sign-up-form-item">
                <Field className="form-input" id="password" name="password" type="password" />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error-message">{formik.errors.password}</div>
                ) : null}
              </div>
            </div>

            <div className="create-btn-wrapper">
              <Button
                text="Create your account"
                className="create-btn"
                type="submit"
                isLoading={loading}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignUpForm;
