import React from 'react';
import './_index.scss';
import logo from '../../assets/img/icons/logoBrandCollector.svg'
const LoadingScreen = () => {
  return (
    <div className="loading-screen ">
    <div className="loader-icon">
      <img src={logo} alt="Shopping Bag Icon" />
    </div>
  </div>
  );
};

export default LoadingScreen;
