import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import WindowDimensions from '../../utilities/windowDimenssions';
import { deleteProductInOrder, updateQuantityOfOrderItem } from '../../slices/order';
import useDebounce from '../../hooks/useDebounce';
import trash from './../../assets/img/icons/trash.svg';
import './_index.scss';

function ProductBasketCard({ data, isLoading, closeModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(data?.quantity || 1);
  useDebounce(
    () => {
      dispatch(updateQuantityOfOrderItem({ quantity: quantity, id: data?.id })).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.message);
          return;
        }
        toast.success('Quantité changé avec succès');
      });
    },
    1000,
    [quantity]
  );

  const incremental = () => {
    setQuantity(quantity + 1);
  };
  const decremental = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const { width, height } = WindowDimensions();
  const isMobile = width < 768;

  return (
    <div className={isLoading ? 'loading product-basket-card' : 'product-basket-card'}>
      <div
        className="product-basket-card-wrapper"
        onClick={() => {
          isMobile && navigate('/product/' + data?.variant?.product?.code);
          isMobile && closeModal();
        }}
      >
        <img
          className="product-image"
          src={data?.variant?.product?.images[0]?.path}
          alt="product"
        />

        <div className="product-content">
          <p className="description">{data?.productName}</p>
          {data?.variant?.optionValues.length > 0 && (
            <div className="option">
              <span className="option-name">Options: </span>
              <span className="option-value">
                {data?.variant?.optionValues?.map((product) => product.value).join(' / ')}
              </span>
            </div>
          )}
          <div className="price">{data?.formattedTotal} </div>
          {/* {isMobile && (
            <div className="quantity">
              <IconButton className="decremental" onClick={decremental}>
                -
              </IconButton>
              <p>{quantity.toString().length < 2 ? `0${quantity}` : quantity}</p>
              <IconButton className="incremental" onClick={incremental}>
                +
              </IconButton>
            </div>
          )} */}
        </div>
      </div>
      <span className="trash-icon" onClick={() => dispatch(deleteProductInOrder(data?.id))}>
        Remove
      </span>
    </div>
  );
}

export default ProductBasketCard;
