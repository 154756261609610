import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../utilities/axios';

const initialState = {
  getPaymentMethodStatus: 'idle',
  getPaymentMethodSuccess: '',
  getPaymentMethodError: '',
  paymentMethod: [],
  paymentProcessStatus: 'idle',
  paymentProcessdSucess: '',
  paymentProcessError: '',
  paymentProcessResult: [],
  paymentOnlineStatus: 'idle',
  paymentMethodSucess: '',
  paymentMethodError: '',
  paymentResult: [],

  directPaymentStatus: 'idle',
  directPaymentResult: [],
  directPaymentError: '',
};

export const getPaymentMethod = createAsyncThunk(
  'payment/getPaymentMethod',
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/payment-methods`);

      data = await response.data;

      if (response.status === 200) {
        // thunkAPI.dispatch(addItemToBasket(basketData));
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const paymentOnlineProcess = createAsyncThunk(
  'payment/getPaymentMethodProcess',
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/v2/shop/stripe/procced-to-payment/`, {
        orderToken: localStorage.getItem('tokenValue'),
      });

      data = await response.data;

      if (response.status === 201) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkPaymentOnline = createAsyncThunk(
  'payment/checkPaymentOnline',
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/v2/shop/stripe/check-payment/`, {
        orderToken: localStorage.getItem('tokenValue'),
      });

      data = await response.data;

      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const directPayment = createAsyncThunk(
  'payment/getDirectPaymentMethodProcess',
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/v2/shop/stripe/direct-procced-to-payment/`, {
        orderToken: localStorage.getItem('tokenValue'),
        productVariant: values.productVariant,
        quantity: values.quantity,
      });

      data = await response.data;

      if (response.status === 201) {
        return data;
        
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: {
    [getPaymentMethod.pending]: (state) => {
      state.getPaymentMethodStatus = 'loading';
    },
    [getPaymentMethod.fulfilled]: (state, action) => {
      state.getPaymentMethodStatus = 'succeeded';
      // state.getPaymentMethodSuccess = action.payload.message;
      state.paymentMethod = action.payload;
      // state.meta = action.payload.meta;
    },
    [getPaymentMethod.rejected]: (state, action) => {
      state.getPaymentMethodStatus = 'failed';
      state.getPaymentMethodError = action.payload;
    },

    [paymentOnlineProcess.pending]: (state) => {
      state.paymentProcessStatus = 'loading';
    },
    [paymentOnlineProcess.fulfilled]: (state, action) => {
      state.paymentProcessStatus = 'succeeded';
      state.paymentProcessResult = action.payload;
    },
    [paymentOnlineProcess.rejected]: (state, action) => {
      state.paymentProcessStatus = 'failed';
      state.paymentProcessError = action.payload;
    },

    [checkPaymentOnline.pending]: (state) => {
      state.paymentOnlineStatus = 'loading';
    },
    [checkPaymentOnline.fulfilled]: (state, action) => {
      state.paymentOnlineStatus = 'succeeded';
      state.paymentResult = action.payload;
    },
    [checkPaymentOnline.rejected]: (state, action) => {
      state.paymentOnlineStatus = 'failed';
      state.paymentProcessError = action.payload;
    },

    [directPayment.pending]: (state) => {
      state.directPaymentStatus = 'loading';
    },
    [directPayment.fulfilled]: (state, action) => {
      state.directPaymentStatus = 'succeeded';
      state.directPaymentResult = action.payload;
    },
    [directPayment.rejected]: (state, action) => {
      state.directPaymentStatus = 'failed';
      state.directPaymentError = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
